import React, { useState, useEffect } from 'react';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const StarPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');

      if (!session_token) {
        navigate('/auth/login'); // 不存在时，跳转到 /auth/login
        return;
      }

      try {
        const response = await fetch('https://v1.vup.name/api/getUnionOrderList', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ session_token, uid }),
        });

        if (!response.ok) throw new Error('您在其他地方登录，为保证您的信息安全，本次请求已被拦截');

        const data = await response.json();
    if (data.data && Array.isArray(data.data.list)) {
      setOrders(data.data.list);
    } else {
      setOrders([]); // 确保orders是一个数组，即使data.data.list是null
    }
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

    fetchData();
  }, []);

  if (loading) 
    return (
      <div className="bg-slate-100   min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">Loading...</div>
          </div>
        </div>
      </div>
    );

  if (error) 
    return (
      <div className="bg-slate-100   min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold"> {error}</div>
          </div>
        </div>
      </div>
    );
  if (orders.length === 0) {
      return (
        <div className="bg-slate-100   min-h-screen flex flex-col justify-center">
          <NavigationBar />
          <div className="flex-grow flex justify-center items-center">
            <div className="card bg-white shadow-xl p-6 rounded-lg">
              <div className="font-bold">您当前没有星光数据！</div>
            </div>
          </div>
        </div>
      );
    }
  
  return (
    <div className="bg-slate-100 min-h-screen">
      <NavigationBar />
      <div className="container mx-auto p-4  flex flex-col items-center">
        {orders.map((order) => (
          <div key={order.id} className="mt-4 p-5 rounded-lg bg-white shadow-xl w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-3/5 relative w-full">
            <img src={`https://v1.vup.name/proxy/avatar?url=${encodeURIComponent(order.cover)}`} alt="直播封面" className="rounded-xl mb-4 w-full"/>
            <div className={`absolute top-2 left-2 rounded-full px-3 py-1 text-white ${
            order.approve_name === "" ? 'bg-green-500 shadow-lg shadow-green-500/50 font-bold' : 'bg-red-500 shadow-lg shadow-red-500/50 font-bold'
          }`}>
            {order.approve_name === "" ? (
                <><FontAwesomeIcon icon={faCheckCircle} /> 已完成</>
            ) : (
                <><FontAwesomeIcon icon={faTimesCircle} /> 终止</>
            )}</div>
            <div className="text-lg font-semibold">
            <div className="stats flex  items-center  shadow mb-4 ">
            <div className="stat bg-gray-100">
              <div className="stat-title">投放星光</div>
              <div className="stat-value">{order.coin_num}</div>
            </div>
            <div className="stat bg-gray-100">
              <div className="stat-title">消耗星光</div>
              <div className="stat-value">{order.coin_cost}</div>
            </div>
            <div className="stat bg-gray-100">
              <div className="stat-title">曝光量</div>
              <div className="stat-value">{order.show_count}</div>
            </div>
            <div className="stat bg-gray-100">
              <div className="stat-title">点击率</div>
              <div className="stat-value">{order.ctr}%</div>
            </div>
          </div>
            {[
              ["推广时间", `${order.start_time} ~ ${order.end_time}`],
              ["直播间标题", order.title],
              ["主播昵称", order.uname],
              ["人气值", order.total_online],
              
            ].map(([label, value]) => (
              <div key={label} className="grid grid-cols-3 gap-4 text-lg font-semibold">
                <div className="col-span-3 bg-gray-100 rounded-lg p-2 shadow mb-2">
                  <div className="grid grid-cols-3">
                    <div>{label}</div>
                    <div className="col-span-2">{value}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </div>
        ))}
      </div>
    </div>
  );
  
  
};

export default StarPage;
