import React, { useState, useEffect } from 'react';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const LivePage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');

      if (!session_token) {
        navigate('/auth/login'); // 不存在时，跳转到 /auth/login
        return;
      }

      try {
        const response = await fetch('https://v1.vup.name/api/GetPersonalRec', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ session_token, uid }),
        });

        if (!response.ok) throw new Error('Network response was not ok');

    const data = await response.json();
    if (data && data.data && Array.isArray(data.data.items)) {
      setOrders(data.data.items);
    } else {
      setOrders([]); // 如果data.data或data.data.items不存在或不是数组，设置为空数组
    }
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

    fetchData();
  }, []);
  const extractDate = (dateTime) => {
    return dateTime.split(' ')[0]; // 假设日期格式为 'YYYY-MM-DD HH:MM:SS'
  };

  // 辅助函数：计算每个日期的总开播时长
  const calculateDailyDurations = () => {
    const durations = {};
    orders.forEach(order => {
      const date = extractDate(order.start_time);
      durations[date] = (durations[date] || 0) + (order.live_time >= 1800 ? order.live_time : 0);
    });
    return durations;
  };

  // 辅助函数：判断是否为当天最后一场开播
  const isLastShowOfDay = (order, index) => {
    const date = extractDate(order.start_time);
    for (let i = index + 1; i < orders.length; i++) {
      if (extractDate(orders[i].start_time) === date) {
        return false;
      }
    }
    return true;
  };

  const dailyDurations = calculateDailyDurations();
  const totalEffectiveTime = Object.values(dailyDurations).reduce((acc, cur) => acc + cur, 0);
  const effectiveDays = Object.values(dailyDurations).filter(duration => duration >= 7200).length;

  if (loading) 
    return (
      <div className="bg-slate-100   min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">Loading...</div>
          </div>
        </div>
      </div>
    );

  if (error) 
    return (
      <div className="bg-slate-100   min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">{error}</div>
          </div>
        </div>
      </div>
    );
  if (orders.length === 0) {
      return (
        <div className="bg-slate-100   min-h-screen flex flex-col justify-center">
          <NavigationBar />
        
          <div className="flex-grow flex justify-center items-center">
            <div className="card bg-white shadow-xl p-6 rounded-lg">
              <div className="font-bold">您当前没有直播信息！</div>
            </div>
          </div>
        </div>
      );
    }
  
  return (
    <div className="bg-slate-100  min-h-screen">
      <NavigationBar />
      <div className="container mx-auto p-4 flex flex-col items-center">
      <div className="stats flex items-center shadow mb-4">
          <div className="stat bg-gray-100">
            <div className="stat-title">总有效开播时长</div>
            <div className="stat-value">{Math.floor(totalEffectiveTime / 3600)}H{Math.floor(totalEffectiveTime % 3600 / 60)}Min</div>
          </div>
          <div className="stat bg-gray-100">
            <div className="stat-title">有效开播天数</div>
            <div className="stat-value">{effectiveDays}</div>
          </div>
        </div>
        {orders.map((order, index) => (
          <div key={order.start_time} className="card bg-base-100 shadow-xl mx-2 my-4 p-4 overflow-hidden max-w-full sm:max-w-md md:max-w-lg lg:max-w-2xl">
            <img src={`https://v1.vup.name/proxy/avatar?url=${encodeURIComponent(order.user_cover)}`} alt="直播封面" className="rounded-xl mb-4 w-full"/>
            {isLastShowOfDay(order, index) && (
              <div className={`absolute top-2 right-2 rounded-full px-3 py-1 text-white ${
                  dailyDurations[extractDate(order.start_time)] >= 7200 ? 'bg-green-500 shadow-lg shadow-green-500/50 font-bold' : 'bg-red-500 shadow-lg shadow-red-500/50 font-bold'
                }`}>
                {dailyDurations[extractDate(order.start_time)] >= 7200 ? (
                  <span>{extractDate(order.start_time)} 已达成有效开播日</span>
                ) : (
                  <span>{extractDate(order.start_time)} 未满足有效开播日</span>
                )}
              </div>
            )}
            <div className={`absolute top-2 left-2 rounded-full px-3 py-1 text-white ${
              order.live_time >= 1800 ? 'bg-green-500 shadow-lg shadow-green-500/50 font-bold' : 'bg-red-500 shadow-lg shadow-red-500/50 font-bold'
            }`}>
            {order.live_time >= 1800 ? (
              <><FontAwesomeIcon icon={faCheckCircle} /> 已达成有效开播</>
            ) : (
              <><FontAwesomeIcon icon={faTimesCircle} /> 未满足有效开播</>
            )}
          </div>
            <div className="text-lg font-semibold">
            <div className="stats  flex  items-center shadow mb-4">
            <div className="stat bg-gray-100">
              <div className="stat-title">总收益（元）</div>
              <div className="stat-value">{order.total_income}</div>
            </div>
            <div className="stat bg-gray-100">
              <div className="stat-title">新增粉丝</div>
              <div className="stat-value">{order.new_fans}</div>
            </div>
            <div className="stat bg-gray-100">
              <div className="stat-title">人气峰值</div>
              <div className="stat-value">{order.max_online}</div>
            </div>
            <div className="stat bg-gray-100">
              <div className="stat-title">弹幕数量</div>
              <div className="stat-value">{order.dm_num}</div>
            </div>
          </div>
              {[
              ["直播时间", `${order.start_time} ~ ${order.end_time}`],
              ["直播标题", order.title],
              ["开播时长", `${Math.floor(order.live_time / 3600)}小时${Math.floor(order.live_time % 3600 / 60)}分`],
              ["开播分区", order.area_text],
              
              
            ].map(([label, value]) => (
              <div key={label} className="grid grid-cols-3 gap-4 text-lg font-semibold">
                <div className="col-span-3 bg-gray-100 rounded-lg p-2 shadow mb-2">
                  <div className="grid grid-cols-3">
                    <div>{label}</div>
                    <div className="col-span-2">{value}</div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LivePage;
