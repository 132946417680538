import React, { useState, useEffect } from 'react';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const SettlementPage = () => {
  const [settlementData, setSettlementData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmState, setConfirmState] = useState({});
  const [modalMessage, setModalMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');

      if (!session_token) {
        navigate('/auth/login'); 
        return;
      }

      try {
        const response = await fetch('https://v1.vup.name/api/settlement', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uid, session_token }),
        });

        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        data.settlements.sort((a, b) => b.date.localeCompare(a.date));
        setSettlementData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const fetchData = async () => {
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    if (!session_token) {
      navigate('/auth/login'); 
      return;
    }

    try {
      const response = await fetch('https://v1.vup.name/api/settlement', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token }),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      data.settlements.sort((a, b) => b.date.localeCompare(a.date));
      setSettlementData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFinalConfirm = (date) => {
    if (confirmState[date] > 0) {
        handleConfirm(date); 
        setConfirmState({ ...confirmState, [date]: null });
    }
  };

  function formatDuration(hours) {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours} 小时 ${minutes} 分钟`;
  }

  const handleInitialConfirm = (date) => {
    setConfirmState({
        ...confirmState,
        [date]: 10
    });

    const interval = setInterval(() => {
        setConfirmState(prevState => {
            if (prevState[date] === 1) {
                clearInterval(interval);
                return { ...prevState, [date]: null };
            }
            return { ...prevState, [date]: prevState[date] - 1 };
        });
    }, 1000);
  };

  const handleConfirm = async (date) => {
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    try {
      const response = await fetch('https://v1.vup.name/api/settlement/status', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token, date, confirmation_status: '1' }),
      });

      const result = await response.json();
      setModalMessage(result.msg);
      setIsModalOpen(true); // 打开模态框
    } catch (error) {
      setModalMessage('未登陆账号，禁止访问！');
      setIsModalOpen(true); // 打开模态框
    }
  };

  const closeModalAndRefresh = () => {
    setIsModalOpen(false);
    fetchData(); // 刷新数据
  };

  if (loading) 
    return (
      <div className="bg-slate-100 min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">Loading...</div>
          </div>
        </div>
      </div>
    );

  if (error) 
    return (
      <div className="bg-slate-100 min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">未登陆账户，禁止访问！</div>
          </div>
        </div>
      </div>
    );

  if (settlementData && settlementData.settlements.length === 0) {
    return (
      <div className="bg-slate-100 min-h-screen flex flex-col justify-center">
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div role="alert" className="m-4 alert shadow-lg alert-warning mb-4 overflow-hidden max-w-full sm:max-w-md md:max-w-lg lg:max-w-3xl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current text-white shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <div>
              <h3 className="font-bold text-white">温馨提示：</h3>
              <div className="font-bold text-white tooltip" data-tip="八木百货结算周期为次月15日-25日">暂无您的结算信息及确认单！</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-slate-100 min-h-screen">
      <NavigationBar />
      
      <div className="container mx-auto p-4 flex flex-col items-center ">
        <div role="alert" className="alert shadow-lg alert-warning mb-4 overflow-hidden max-w-full sm:max-w-md md:max-w-lg lg:max-w-3xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current text-white shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
          <div>
            <h3 className="font-bold text-white">温馨提示</h3>
            <div className="font-bold text-white">如果发现结款信息有误，请及时联系运营确认正确流水及结算信息！</div>
          </div>
        </div>
        <div role="alert" className="alert shadow-lg alert-success overflow-hidden max-w-full sm:max-w-md md:max-w-lg lg:max-w-3xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current text-white shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <div>
            <h3 className="font-bold text-white">温馨提示</h3>
            <div className="font-bold text-white">2024年之前的数据按照旧比例发放，2024年起不再扣除打款手续费！</div>
          </div>
        </div>
        {Array.isArray(settlementData?.settlements) && settlementData.settlements.map((settlement, index) => (
          <div key={index} className="card bg-base-100 shadow-xl mx-4 my-4 overflow-hidden max-w-full sm:max-w-md md:max-w-lg lg:max-w-3xl w-full">
            <div className={`absolute top-3 left-3 rounded-full px-3 py-1 text-white ${
                  settlement.confirmation_status === "0" ? 'bg-red-500 shadow-lg shadow-red-500/50 font-bold' : 'bg-green-500 shadow-lg shadow-green-500/50 font-bold'
              }`}>
                {settlement.confirmation_status === "0" ? (
                  <span>未确认结算信息，请及时确认！</span>
                ) : (
                  <span>已确认结算信息，请耐心等待结算！</span>
                )}
            </div>
            <div className="stats  flex  items-center shadow mb-4 mt-4">
              <div className="stat bg-gray-100">
                <div className="stat-title text-red-500 font-bold tooltip tooltip-bottom" data-tip="扣税之后的收入">到手收益（税后）</div>
                <div className="stat-value text-red-500">{((settlement.total_earnings + settlement.operational_rewards_penalties) * settlement.bilibili_revenue_share_ratio *2 ).toFixed(2)}</div>
              </div>
              <div className="stat bg-gray-200">
                <div className="stat-title">总流水</div>
                <div className="stat-value">{settlement.total_turnover}</div>
              </div>
              <div className="stat bg-gray-200">
                <div className="stat-title">总收益  +{settlement.operational_rewards_penalties} 元（平台奖惩）</div>
                <div className="stat-value">{settlement.total_earnings}</div>
              </div>
            </div>
            {[
              ["结算周期", settlement.date],
              ["税后分成比例", `${settlement.bilibili_revenue_share_ratio*200}%`],
              ["税务比例", `8%`],
              ["平台奖惩", `${settlement.operational_rewards_penalties} （元）`],
              ["有效时长", formatDuration(parseFloat(settlement.streaming_duration))],
              ["有效天数", `${settlement.days_of_streaming} （天）`],
            ].map(([label, value]) => (
              <div key={label} className="grid grid-cols-3 gap-4 text-lg font-semibold">
                <div className="col-span-3 bg-gray-100 rounded-lg p-2 shadow mb-2">
                  <div className="grid grid-cols-3">
                    <div>{label}</div>
                    <div className="col-span-2">{value}</div>
                  </div>
                </div>
              </div>
            ))}
            <div className="bg-gray-100 rounded-lg p-6 shadow mb-2">
              <div className="grid  gap-4 text-lg font-semibold">
                <div>收款人姓名: {settlementData.settlement_details.recipient_name}</div>
                <div>结算方式: {settlementData.settlement_details.settlement_method}</div>
                <div>收款人联系方式: {settlementData.settlement_details.recipient_contact}</div>
                <div>收款人身份证号: {settlementData.settlement_details.recipient_id_number}</div>
                <div>银行卡号: {settlementData.settlement_details.bank_card}</div>
                <div>银行名称: {settlementData.settlement_details.bank_name}</div>
                <div>开户行: {settlementData.settlement_details.opening_bank}</div>
                <div>移动支付收款方式: {settlementData.settlement_details.online_pay}</div>
              </div>
            </div>
            {settlement.confirmation_status !== "1" && (
              <>
                {confirmState[settlement.date] > "0" ? (
                  <button onClick={() => handleFinalConfirm(settlement.date)} className="btn btn-outline btn-success mt-4">
                    如果您确定结算信息正确，请再次点击确认 ({confirmState[settlement.date]})
                  </button>
                ) : (
                  <button onClick={() => handleInitialConfirm(settlement.date)} className="btn btn-outline btn-success mt-4">
                    确认
                  </button>
                )}
              </>
            )}
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">确认信息</h3>
            <p className="py-4 font-bold">{modalMessage}</p>
            <div className="modal-action">
              <button className="btn btn-active btn-neutral" onClick={closeModalAndRefresh}>确认</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettlementPage;
