import React, { useState, useEffect } from 'react';
import NavigationBar from '../pages/NavigationBar';
import Watermark from '../pages/Watermark';
import { useNavigate } from 'react-router-dom';
import DisableDevtool, { DetectorType } from 'disable-devtool';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';

const BoxlistPage = () => {
  const [expandedMessages, setExpandedMessages] = useState({});
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [filterShow, setFilterShow] = useState('all'); // 'all', '1', '0'
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' 或 'desc'
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const navigate = useNavigate();
  const filteredMessages = modalContent && modalContent.message 
  ? modalContent.message
    .filter(msg => filterShow === 'all' || msg.show.toString() === filterShow)
    .sort((a, b) => sortOrder === 'asc' ? a.message_id - b.message_id : b.message_id - a.message_id)
  : [];
  const openAddModal = () => {

  };
  useEffect(() => {
    const fetchData = async () => {
      const uid = localStorage.getItem('uid');
      const sessionToken = localStorage.getItem('session_token');
      try {
        const response = await fetch('https://v1.vup.name/box/info', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            uid,
            session_token: sessionToken
          })
        });
        if (!response.ok) throw new Error('请求失败');
        const data = await response.json();
        setInfo(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // 检查 localStorage 中的 uid 和 session_token
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');

    // 如果不存在，则重定向到 /auth/login
    if (!uid || !sessionToken) {
      navigate('/auth/login');
      return; // 在重定向后终止执行
    }

    // ... 现有的 fetchData 逻辑
  }, [navigate]); 
  const uid = localStorage.getItem('uid');
  const name = localStorage.getItem('username');
  const toggleMessageExpansion = (messageId) => {
    setExpandedMessages(prevState => ({
      ...prevState,
      [messageId]: !prevState[messageId]
    }));
  };
  const fetchModalContent = async (tagId) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    try {
      const response = await fetch('https://v1.vup.name/box/list', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token: sessionToken, tag_id: tagId })
      });
      if (!response.ok) throw new Error('请求失败');
      const data = await response.json();
      setModalContent(data);
    } catch (error) {
      setError(error.message);
    }
  };
  
  const handleShowToggle = async (messageId, currentShow, tagId) => {
    setLoadingMessageId(messageId); // 设置加载状态
    const sessionToken = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');
    const isShow = currentShow === 1 ? '0' : '1';
  
    try {
      // 发送更新请求
      const response = await fetch('https://v1.vup.name/box/show', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          session_token: sessionToken,
          uid,
          message_id: messageId,
          is_show: isShow
        })
      });
  
      if (!response.ok) throw new Error('请求失败');
  
      // 请求成功后，重新获取最新的数据
      const res = await fetch('https://v1.vup.name/box/list', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token: sessionToken, tag_id: tagId })
      });
  
      if (!res.ok) throw new Error('获取数据失败');
      const newData = await res.json();
  
      // 更新模态框内容
      setModalContent(newData);
  
    } catch (error) {
      console.error("Error updating show status:", error);
    } finally {
      setLoadingMessageId(null); // 清除加载状态
    }
  };
  
  
  
  
  
  const handleCardClick = async (tagId) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    try {
      const response = await fetch('https://v1.vup.name/box/list', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token: sessionToken, tag_id: tagId })
      });
      if (!response.ok) throw new Error('请求失败');
      const data = await response.json();
      setModalContent(data);
      setModalOpen(true);
      setFilterShow('all');
      setSortOrder('asc');
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return  <div className="flex justify-center items-center overflow-x-auto mt-3 max-w-6xl mx-auto ">
  <div role="alert" className="alert alert-warning shadow-lg my-4 mx-2">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
  <span className="text-white font-bold">{error}</span>
</div>
</div>;

  return (
    <div className="bg-gradient-to-r from-cyan-100 to-pink-100 min-h-screen">
      <Watermark uid={uid} name={name} />
      <NavigationBar />
    <div>
    <div className="container mx-auto">
    <button className="flex btn btn-neutral w-full rounded-lg text-base max-w-2xl mx-auto  items-center m-4" onClick={openAddModal}>
          <FontAwesomeIcon icon={faPlus} /> 添加收集活动
        </button>
    {loading && (
        <div className="flex justify-center items-center overflow-x-auto mt-3 max-w-6xl mx-auto ">
            <div role="alert" className="alert shadow-lg my-4 mx-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current text-blue-500 shrink-0 w-6 h-6 animate-spin">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span className="font-bold">正在加载中...</span>
          </div>
        </div>
        
    )}
        {error && 
        <div className="flex justify-center items-center overflow-x-auto mt-3 max-w-6xl mx-auto ">
        <div role="alert" className="alert alert-warning shadow-lg my-4 mx-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <span className="text-white font-bold">{error}</span>
      </div>
    </div>
        }
      {/* 渲染卡片 */}
      {info && info.tags.map(tag => (
        <div className="overflow-x-auto max-w-3xl mx-auto  py-3 ">
        <div key={tag.tag_id} className="card mx-auto shadow-sm bg-white  font-bold  w-full" onClick={() => handleCardClick(tag.tag_id)}>
          <div className="card-body">
          <p className="shadow-sm p-4 rounded-lg mb-3 bg-gray-100 text=gray-300 ">
                <span className="font-bold bg-white p-2 mr-2 rounded-lg shadow-sm">#{tag.tag_id}</span> {tag.tag_name}
              </p>
            <div className="prose">
            <ReactMarkdown>{tag.tag_info}</ReactMarkdown>
            </div>
          </div>
        </div>
        </div>
      ))}
          
      {/* 模态框 */}
      {modalOpen && (
        <div className="modal modal-open">
          <div className="modal-box w-full max-w-screen-2xl overflow-hidden">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-bold text-lg">问题管理</h3>
            <div className="flex gap-2">
              <select className="select select-bordered max-w-xs" onChange={(e) => setFilterShow(e.target.value)}>
                <option value="all">所有消息</option>
                <option value="1">展示</option>
                <option value="0">归档</option>
              </select>
              <select className="select select-bordered max-w-xs" onChange={(e) => setSortOrder(e.target.value)}>
                <option value="asc">正序</option>
                <option value="desc">倒序</option>
              </select>
              <button className="btn btn-square btn-outline" onClick={() => setModalOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
              </button>
            </div>
          </div>
          <div className="py-4 overflow-auto max-h-[1000px]">
          
          {modalContent && modalContent.message && modalContent.message.length > 0 ? (
            modalContent.message
              .filter(msg => filterShow === 'all' || msg.show.toString() === filterShow)
              .sort((a, b) => sortOrder === 'asc' ? a.message_id - b.message_id : b.message_id - a.message_id)
              .map(msg => (
                <div className={`flex items-start shadow-sm p-4 rounded-lg mb-3 ${msg.show === 0 ? 'bg-gray-100 text-gray-300' : 'bg-gray-300'}`} key={msg.message_id}>
                 {loadingMessageId === msg.message_id ? (
                        <div className="flex items-center justify-center bg-white px-[0.59rem] py-3 mr-4 rounded-lg shadow-sm flex-shrink-0">
                          <span className="loading loading-spinner" style={{ width: '1em', height: '1em' }}></span>
                        </div>
                      ) : (
                        <span onClick={() => handleShowToggle(msg.message_id, msg.show, modalContent.tag_id)} className="font-bold bg-white p-2 mr-4 rounded-lg shadow-sm flex-shrink-0 cursor-pointer">#{msg.message_id}</span>
                      )}

                  <div className="prose flex-grow">
                    <ReactMarkdown>
                      {expandedMessages[msg.message_id] || msg.message.length <= 50 ? msg.message : `${msg.message.substring(0, 50)}...`}
                    </ReactMarkdown>
                    {msg.message.length > 50 && (
                      <button className="btn btn-outline btn-xs" onClick={() => toggleMessageExpansion(msg.message_id)}>
                        {expandedMessages[msg.message_id] ? '收起' : '展开'}
                      </button>
                    )}
                  </div>
                </div>
              ))
          ) : modalContent && modalContent.msg ? (
            <p className="shadow-sm p-4 rounded-lg mb-3 bg-gray-100 font-bold">
              {modalContent.msg}
            </p>
          ) : null}

            
          </div>
          </div>
        </div>
      )}
      
      </div>
    </div>
    </div>
    
  );
};

export default BoxlistPage;
