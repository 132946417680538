import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPoo,faFire, faExclamationCircle, faUser, faBan } from '@fortawesome/free-solid-svg-icons';

const LiandongPage = () => {
    const [posts, setPosts] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [time, setTime] = useState('');
    const [content, setContent] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [confirmState, setConfirmState] = useState({});
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedPosts, setExpandedPosts] = useState({});
    const [filterType, setFilterType] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState('');



    

    useEffect(() => {
        const fetchData = async () => {
          const session_token = localStorage.getItem('session_token');
          const uid = localStorage.getItem('uid');
    
          if (!session_token) {
            navigate('/auth/login'); 
            return;
          }
          
        };
        fetchTypeList();
        fetchPosts();
        fetchData();
      }, [navigate]);

    
    const fetchTypeList = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');

        try {
            const response = await axios.post('https://v1.vup.name/liandongtype', {
                session_token,
                uid
            });
            setTypeList([{ type_id: '', type_name: '请选择类型' }, ...response.data]);
        } catch (error) {
            console.error("Error fetching type list:", error);
        }
    };

    const fetchPosts = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            const response = await axios.post('https://v1.vup.name/liandonglist', {
                session_token,
                uid
            });
            let fetchedPosts = response.data;
    
            // 对帖子按照 ld_id 从大到小排序
            fetchedPosts = fetchedPosts.sort((a, b) => b.ld_id - a.ld_id);
    
            // 对帖子按照 type_name 进行筛选
            if (filterType) {
                fetchedPosts = fetchedPosts.filter(post => post.type_name === filterType);
            }
    
            // 对帖子按照 state 进行筛选
            if (selectedState) {
                fetchedPosts = fetchedPosts.filter(post => post.state.toString() === selectedState);
            }
    
            // 解码帖子内容
            const decodedPosts = fetchedPosts.map(post => ({
                ...post,
                content: decodeURIComponent(unescape(post.content))
            }));
    
            setPosts(decodedPosts);
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    };

    const handleSubmit = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');

        try {
            await axios.post('https://v1.vup.name/liandong', {
                session_token,
                uid,
                type_id: selectedType,
                time,
                content
            });
            setShowModal(false);
            window.location.reload();
        } catch (error) {
            console.error("Error submitting post:", error);
        }
    };

    const joinLiandong = async (ld_id) => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            const response = await axios.post('https://v1.vup.name/joinliandong', {
                session_token,
                uid,
                ld_id
            });
            setAlertModalContent(response.data.msg);
            setShowAlertModal(true);
        } catch (error) {
            console.error("Error joining liandong:", error);
            if (error.response && error.response.data) {
                setAlertModalContent(error.response.data.error);
                setShowAlertModal(true);
            }
        }
    };

    const getStatusIcon = (state) => {
        switch (state) {
            case 0: return <FontAwesomeIcon icon={faUser} className="animate-bounce"/>;
            case 1: return <FontAwesomeIcon icon={faCheckCircle} className="animate-ping"/>;
            case 2: return <FontAwesomeIcon icon={faPoo} className="animate-bounce"/>;
            case 3: return <FontAwesomeIcon icon={faExclamationCircle} className="animate-bounce"/>;
            case 4: return <FontAwesomeIcon icon={faFire}  className="animate-bounce"/>;
            case 5: return <FontAwesomeIcon icon={faBan} className="animate-spin"/>;
            default: return null;
        }
    };

    const getStatusColor = (state) => {
        switch (state) {
            case 0: return 'bg-yellow-500 shadow-yellow-500/30';
            case 1: return 'bg-green-500 shadow-green-500/30';
            case 2: return 'bg-yellow-900 shadow-yellow-900/30';
            case 3: return 'bg-blue-500 shadow-blue-500/30';
            case 4: return 'bg-red-500 shadow-red-500/50';
            case 5: return 'bg-gray-500 shadow-gray-500/30';
            default: return 'bg-gray-300 shadow-gray-300/50';
        }
    };

    const renderComments = (post) => {
        if (post.astatus === 1 && post.comments) {
            return (
                <div role="alert" className="alert alert-warning shadow-lg mt-2 mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" stroke="white" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <div>
                        <h3 className="font-bold text-white">{post.comments}</h3>
                    </div>
                </div>
            );
        }
        return null;
    };
    const toggleExpansion = (postId) => {
        setExpandedPosts(prevState => ({
            ...prevState,
            [postId]: !prevState[postId]
        }));
    };
    
    useEffect(() => {
        fetchPosts();
    }, [filterType, selectedState]);
    const getStatusText = (state) => {
        const statusMap = {
            0: '自建联动',
            1: '公会活动',
            2: 'B站活动',
            3: '跨公会联动',
            4: '热门活动',
            5: '终止'
        };
        return statusMap[state] || '未知状态';
    };

    return (
        <div className="bg-slate-100  min-h-screen">
            <NavigationBar />

            <div className="container mx-auto flex flex-col items-center mt-4 p-5 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-2/5 relative w-full">
            <div role="alert" className="alert alert-warning">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            <span className="font-bold">为避免纠纷，创建活动后不支持主播自行取消，如果确认活动完结或者达成一致取消活动，请联系八木百货运营!</span>
            </div>
                <button className="btn btn-active btn-neutral mt-4 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-7/8 relative w-full" onClick={() => setShowModal(true)}>发起联动</button> 
                <div className="form-control mt-4 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-7/8 relative w-full">
                <div className="flex flex-col sm:flex-row gap-2">
                    <select className="select select-bordered w-full sm:w-1/2" value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                        <option value="">所有类型</option>
                        {typeList.map((type) => (
                            <option key={type.type_id} value={type.type_name}>{type.type_name}</option>
                        ))}
                    </select>

                    <select className="select select-bordered w-full sm:w-1/2" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                        <option value="">所有状态</option>
                        <option value="0">自建联动</option>
                        <option value="1">公会活动</option>
                        <option value="2">B站活动</option>
                        <option value="3">跨公会联动</option>
                        <option value="4">热门活动</option>
                        <option value="5">终止</option>
                    </select>
                </div>
                </div>
                {posts.map(post => (
                    <div key={post.ld_id} className="mt-4 p-5 rounded-lg bg-white shadow-md w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-7/8 relative w-full">
                        <div className={`absolute top-2 left-2 rounded-full px-3 py-1 text-white ${getStatusColor(post.state)} shadow-lg`}>
                            {getStatusIcon(post.state)}
                            <span className="ml-2 font-bold">{getStatusText(post.state)}</span>
                        </div>
                        <div className="absolute top-2 shadow-green-500/50 right-2 rounded-full px-3 py-1 text-white font-bold bg-green-500 ">
                        <div className="tooltip tooltip-bottom" data-tip="开始时间">
                            <span>{post.time}</span>
                        </div>
                        </div>

                        <div className="card-body p-2">
                            {renderComments(post)}
                            <div className="prose w-full"  style={{ maxWidth: '150ch' }}>
                            <h2 className="text-violet-600 mt-6"># {post.ld_id} {post.type_name}</h2>
                            <div className="bg-slate-50 rounded-lg p-4 mt-2">
                            <Markdown>
                                {expandedPosts[post.ld_id] || post.content.length <= 200 ? post.content : `${post.content.substring(0, 200)}...`}
                            </Markdown>
                            {post.content.length > 200 && (
                                <button className="btn btn-outline btn-error btn-xs breath-button" onClick={() => toggleExpansion(post.ld_id)}>
                                    {expandedPosts[post.ld_id] ? '收起' : '更多内容请点击展开'}
                                </button>
                            )}
                            </div>
                            </div>
                            <button 
                                className="btn btn-outline btn-success mt-2 hover:bg-success hover:text-white"
                                onClick={() => joinLiandong(post.ld_id)}
                            >参与活动
                            </button>
                        </div>
                    </div>
                ))}

                {showModal && (
                    <div className="modal modal-open">
                        <div className="modal-box">
                            <span className="text-xl font-bold">发起联动</span>
                            <div className="form-control">
                                <label className="label">
                                </label>
                                <select className="select select-bordered w-full" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                    {typeList.map((type) => (
                                        <option key={type.type_id} value={type.type_id}>{type.type_name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">选择活动开始时间</span>
                                </label>
                                <input type="datetime-local" className="input input-bordered w-full" value={time} onChange={(e) => setTime(e.target.value)} />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">填写活动策划（支持Markdown）</span>
                                </label>
                                <textarea className="textarea textarea-bordered w-full" value={content} onChange={(e) => setContent(e.target.value)} />
                            </div>

                            <div className="modal-action">
                                <button className="btn btn-active btn-neutral" onClick={handleSubmit}>确认申请</button>
                                <button className="btn" onClick={() => setShowModal(false)}>关闭</button>
                            </div>
                        </div>
                    </div>
                )}
                {showAlertModal && (
                    <div className="modal modal-open">
                        <div className="modal-box">
                            <p className="font-bold">{alertModalContent}</p>
                            <div className="modal-action">
                                <button className="btn" onClick={() => setShowAlertModal(false)}>关闭</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiandongPage;
