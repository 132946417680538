import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavigationBar from '../pages/NavigationBar';
import { marked } from 'marked'; 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave,faP, faFileUpload } from '@fortawesome/free-solid-svg-icons';

function TaskPage() {
  const [tasks, setTasks] = useState([]);
  
  useEffect(() => {
    const sessionToken = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    if (!sessionToken || !uid) {
      window.location.href = '/auth/login';
      return;
    }

    axios.post('https://v1.vup.name/api/tasks/available', { session_token: sessionToken, uid: uid })
      .then(response => {
        setTasks(response.data);
      })
      .catch(error => {
        console.error('Error fetching tasks', error);
      });
  }, []);

  return (
    <div className="bg-slate-100  min-h-screen">
      <NavigationBar /> {/* 引入导航栏 */}
      <div className="container mx-auto p-4">
        <h1 className="font-bold mb-4 text-2xl">任务列表</h1>
        {tasks.map((task) => (
          <div key={task.TaskID} className="bg-white p-4 rounded-lg shadow mb-4">
            <h2 className="font-semibold text-lg">{task.TaskName}</h2>
            <article
              className="prose prose-zinc overflow-x-auto max-w-none w-full p-4 bg-gray-50 rounded-lg mt-4"
              dangerouslySetInnerHTML={{ __html: marked.parse(task.TaskContent) }}
            ></article>
            {/* Steps 组件 */}
            <div className="steps-container overflow-x-auto ">
            <ul className="steps  w-full mt-4 min-w-32"  style={{ width: '1400px' }}>
              {Object.values(task.Stages).map((stage) => (
                <li key={stage.StageID} 
                className={`step  ${stage.StageState === "1" ? 'step-accent' : stage.StageState === "2" ? 'step-error' : ''}`}
                data-content={stage.StageState === "1" ? "✓" : stage.StageState === "2" ? "✕" : undefined}>
                <span className="font-bold">{stage.StageName}</span>
                <article
                  className={`prose prose-zinc overflow-x-auto p-1 px-2 rounded-md text-xs max-w-64 opacity-80 font-bold mt-2 ${stage.StageState === "1" ? 'bg-green-50 text-green-500' : 'bg-gray-200 text-gray-500'}`}
                  dangerouslySetInnerHTML={{ __html: marked.parse(stage.StageDescription) }}>
                </article>
                
                  {/* 显示条件值 */}
                  {stage.Conditions.map((condition) => {
                    // 如果阶段状态为“2”，显示未满足条件的消息
                    if (stage.StageState === "2") {
                      return (
                        <div key={condition.ConditionID} className="p-1 px-2 mt-2 rounded-md opacity-80 text-xs font-bold break-word break-all mt-2 text-xs font-bold bg-red-50 text-red-500">
                          未满足活动相关基础条件，资格取消！
                        </div>
                      );
                    } else {
                      // 当condition.Value不为空时，渲染达标奖励积分的卡片
                      if (condition.Value) {
                        return (
                          <div key={condition.ConditionID} className={`p-1 px-2 mt-2 rounded-md opacity-80 text-xs font-bold break-word break-all mt-2 text-xs font-bold ${stage.StageState === "1" ? 'bg-green-50 text-green-500' : 'bg-gray-200 text-gray-500'}`}>
                            <FontAwesomeIcon icon={faP} className="mr-1"/>达标奖励积分：{condition.Value}
                          </div>
                        );
                      }
                      // 如果condition.Value为空，则不渲染任何内容
                      return null;
                    }
                  })}

                </li>
              ))}
            </ul>
            </div>
            {/* 显示总进度 */}
            <progress className="progress w-full h-2 mt-4 -mb-2 bg-gray-100 opacity-50" value={calculateProgress(task)} max="100"></progress>
          </div>
        ))}
      </div>
    </div>
  );
}

function calculateProgress(task) {
  const stages = Object.values(task.Stages);
  const completedStages = stages.filter(stage => stage.StageState === 'Completed').length;
  const totalStages = stages.length;
  return (completedStages / totalStages) * 100;
}


export default TaskPage;
