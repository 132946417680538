import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import NavigationBar from '../pages/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faClock, faStop, faPlay, faCalendar, faPaperclip, faEdit,faShareNodes,faTimes,faTrash,faShareFromSquare,faCopy,faCircleInfo,faArrowUp91 } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function LuboSharePage() {
  const [shares, setShares] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedShare, setSelectedShare] = useState({});
  const [submitSuccessModalIsOpen, setSubmitSuccessModalIsOpen] = useState(false);
  const [docShareModalIsOpen, setDocShareModalIsOpen] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [docShareData, setDocShareData] = useState({
    url: '',
    name: '',
    doc_content: '',
    icon: 'none',
    token: ''
  });
  const [editedShare, setEditedShare] = useState({
    describe: '',
    num: 1,
    is_stop: false,
  });
  const uid = localStorage.getItem('uid');
  const sessionToken = localStorage.getItem('session_token');
  const showSubmitSuccessModal = () => {
    setSubmitSuccessModalIsOpen(true);
    setTimeout(() => {
      setSubmitSuccessModalIsOpen(false);
    }, 3000); // 3秒后自动关闭模态框
  };

  const fetchShares = async () => {
    const sessionToken = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');
  
    if (!sessionToken || !uid) {
      window.location.href = '/auth/login';
      return;
    }
    try {
      const response = await axios.post('https://v1.vup.name/lubo/share/list', { session_token: sessionToken, uid: uid });
      setShares(response.data); // 假设后端返回的是整个分享列表
    } catch (error) {
      console.error('Error fetching shares', error);
    }
  };
  
  useEffect(() => {
    fetchShares();
  }, []);
  
  const openEditModal = (share) => {
    setSelectedShare(share);
    setEditedShare({
      describe: share.describe || '',
      num: share.num || 1,
      is_stop: share.is_stop || false,
    });
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const handleDeleteUrl = async (token, urlId, event) => {
    event.stopPropagation();
    try {
      const response = await axios.post('https://v1.vup.name/lubo/docs/edit', {
        uid,
        session_token: sessionToken,
        token,
        url_id: urlId
      });
  
      if (response.status === 200) {
        setSelectedShare(prevShare => ({
          ...prevShare,
          url: prevShare.url.filter(url => url.url_id !== urlId)
        }));
        setForceUpdate(f => !f); // 切换状态以强制更新组件
      }
    } catch (error) {
      console.error('Error deleting URL', error);
    }
  };
  
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    // 从localStorage中获取uid和session_token
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
  
    if (!uid || !sessionToken) {
      alert('Session not found, please login again.');
      return;
    }
  
    const payload = {
      uid: uid,
      session_token: sessionToken,
      token: selectedShare.token,
      share_describe: editedShare.describe,
      num: editedShare.num.toString(),
      is_delete: "0",
      is_stop: editedShare.is_stop ? "1" : "0",
    };
  
    try {
      const response = await axios.post('https://v1.vup.name/lubo/share/edit', payload);
      // 处理响应，例如展示成功消息或更新UI
      showSubmitSuccessModal();
      
      fetchShares();
      
    } catch (error) {
      console.error('Error submitting edit', error);
      // 展示错误消息
    }
  };

  const handleDeleteShare = async (token) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
  
    if (!uid || !sessionToken) {
      alert('Session not found, please login again.');
      return;
    }
  
    const payload = {
      uid,
      session_token: sessionToken,
      token,
      is_delete: "1", // 标记为删除
    };
  
    try {
      const response = await axios.post('https://v1.vup.name/lubo/share/edit', payload);
      showSubmitSuccessModal();
      fetchShares();
    } catch (error) {
      console.error('Error deleting share', error);
      // 展示错误消息
    }
  };

  const openDocShareModal = (token) => {
    setDocShareData({ ...docShareData, token: token }); // 设置当前分享的token
    setDocShareModalIsOpen(true);
  };
  
  const handleDocShareSubmit = async (e) => {
    e.preventDefault();
    // URL校验逻辑
    if (!/^(https?:\/\/)[^\s$.?#].[^\s]*$/.test(docShareData.url)) {
      alert('请输入正确的链接');
      return;
    }
  
    // 准备提交的数据
    const payload = {
      ...docShareData,
      uid: uid,
      session_token: sessionToken
    };
  
    try {
      const response = await axios.post('https://v1.vup.name/lubo/docs', payload);
      // 根据响应处理逻辑...
      setDocShareModalIsOpen(false); // 关闭文档分享模态框
      fetchShares();
      showSubmitSuccessModal();
      // 显示成功或错误消息
    } catch (error) {
      console.error('Error submitting document share', error);
    }
  };

  return (
    <div className="bg-slate-100 min-h-screen">
      <NavigationBar />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {shares.map((share, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-4 bg-base-100 shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between relative">
              <div className="absolute top-0 right-0 p-2">
                <CopyToClipboard text={`https://vup.im/share/${share.token}`}>
                  <button className="btn btn-xs"><FontAwesomeIcon icon={faCopy} /></button>
                </CopyToClipboard>
              </div>
              <div>
                
                <h3 className="card-title text-blue-500">{share.path}</h3>
                <p className="text-sm text-gray-600 font-bold flex items-center"><FontAwesomeIcon icon={faClock} className="text-gray-500 mr-2" />过期时间: {share.out_time}</p>
                <p className="text-sm text-gray-600 font-bold flex items-center"><FontAwesomeIcon icon={faCalendar} className="text-gray-500 mr-2" />分享日期: {share.share_time}</p>
                <p className="text-sm text-gray-600 font-bold flex items-center"><FontAwesomeIcon icon={faDownload} className="text-gray-500 mr-2" />下载量: {share.download_num}</p>
                <p className="text-sm text-gray-600 font-bold flex items-center"><FontAwesomeIcon icon={faArrowUp91} className="text-gray-500 mr-2" />许可次数: {share.num}</p>
                <p className="text-sm text-gray-600 font-bold flex items-center">
                  {share.is_stop ? (
                    <><FontAwesomeIcon icon={faPlay} className="text-green-500 mr-3" />分享中...</>
                  ) : (
                    <><FontAwesomeIcon icon={faStop} className="text-red-500 mr-3" />已暂停分享</>
                  )}
                </p>
                <p className="text-sm text-gray-600 font-bold flex items-center"><FontAwesomeIcon icon={faCircleInfo} className="text-gray-500 mr-2" />简介: {share.describe}</p>
              </div>
              <div className="card-actions justify-end mt-4">
                    {share.url && share.url.map((u, urlIndex) => (
                      <a key={urlIndex} href={u.url} target="_blank" rel="noopener noreferrer" className="btn btn-xs btn-outline ">
                        <FontAwesomeIcon icon={faPaperclip}  className="mr-1" />{u.name}
                      </a>
                    ))}
                  </div>
              <div className="card-actions justify-end mt-4">
                <button className="btn btn-error btn-sm text-white" onClick={() => handleDeleteShare(share.token)}><FontAwesomeIcon icon={faTrash} className=" text-white"  />删除分享</button>
                
                <button className="btn btn-primary btn-sm" onClick={() => openEditModal(share)}><FontAwesomeIcon icon={faEdit} className=" text-white" />编辑分享</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {submitSuccessModalIsOpen && (
        <div className="modal modal-open " style={{ zIndex: 100 }}>
          <div className="modal-box">
            <h3 className="font-bold text-lg">提交成功!</h3>
            <p className="py-4">您的信息已成功提交。</p>
            <div className="modal-action">
              <a href="#" className="btn" onClick={() => setSubmitSuccessModalIsOpen(false)}>关闭</a>
            </div>
          </div>
        </div>
      )}
      {editModalIsOpen && (
        <div id="editModal" className="modal modal-open" style={{ zIndex: 50 }}>
          <div className="modal-box w-96">
            <h3 className="font-bold text-lg">编辑分享<FontAwesomeIcon icon={faShareNodes} className="ml-2 text-gray-600" /></h3>
            <button className="btn btn-neutral w-full mt-4" onClick={() => openDocShareModal(selectedShare.token)}>创建文档分享</button>
            <form onSubmit={handleEditSubmit} className="py-4">
                  {/* Form elements with Tailwind CSS and DaisyUI */}
                  <div className="form-control">
                    <label className="label" htmlFor="describe">
                      <span className="label-text font-bold">简介</span>
                    </label>
                    <textarea
                      id="describe"
                      name="describe"
                      className="textarea textarea-bordered h-24 textarea-primary"
                      placeholder="请输入简介内容~"
                      value={editedShare.describe}
                      onChange={(e) => setEditedShare({ ...editedShare, describe: e.target.value })}
                    ></textarea>
                  </div>

                  <div className="form-control  mt-4">
                    <label className="label" htmlFor="num">
                      <span className="label-text font-bold">许可下载次数</span>
                    </label>
                    <input
                      type="range"
                      id="num"
                      name="num"
                      className="range range-primary range-xs"
                      min="1"
                      max="10"
                      value={editedShare.num}
                      onChange={(e) => setEditedShare({ ...editedShare, num: parseInt(e.target.value, 10) })}
                    />
                    <div className="w-full flex justify-between text-xs px-2 font-bold mt-1">
                      <span>1</span>
                      <span>2</span>
                      <span>3</span>
                      <span>4</span>
                      <span>5</span>
                      <span>6</span>
                      <span>7</span>
                      <span>8</span>
                      <span>9</span>
                      <span>10</span>
                    </div>
                  </div>

                  <div className="form-control mb-2 mt-4">
                    <label className="label cursor-pointer">
                      <span className="label-text font-bold">是否分享</span>
                      <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        checked={editedShare.is_stop}
                        onChange={(e) => setEditedShare({ ...editedShare, is_stop: e.target.checked })}
                      />
                    </label>
                  </div>

                  {selectedShare.url && selectedShare.url.map((urlInfo, index) => (
                    <div key={index} className="group relative bg-gray-50 border-dashed border-2 border-indigo-600 rounded-lg p-4 mb-3 drop-shadow hover:drop-shadow-lg transition-shadow duration-300 overflow-hidden">
                      <div className="absolute top-2 right-2 "  style={{zIndex: '80'}}>
                      <button
                        onClick={(event) => handleDeleteUrl(selectedShare.token, urlInfo.url_id, event)}
                        className="text-gray-400 hover:text-red-500 ">
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                      </div>
                      <div className="absolute right-0 top-0 h-full w-1/4 bg-gray-100 bg-opacity-10 flex items-center justify-center">
                        <p className="text-gray-100 font-bold text-6xl">{urlInfo.url_id}</p>
                      </div>
                      <div className="pr-1/4"> {/* Padding right to make space for url_id */}
                        <p className="font-semibold text-sm text-gray-800  truncate">{urlInfo.name}</p>
                        <a href={urlInfo.url} target="_blank" rel="noopener noreferrer" className="text-xs text-blue-500 hover:text-blue-600 truncate block">{urlInfo.url}</a>
                        <p className="text-xs text-gray-500 mb-1 truncate">{urlInfo.content}</p>
                      </div>
                    </div>
                  ))}
                  <div className="mt-6 modal-action">
                    <button type="submit" className="btn btn-primary">提交</button>
                    <label htmlFor="editModal" className="btn" onClick={closeEditModal}>关闭</label>
                  </div>
                </form>
                
          </div>
        </div>
      )}
      {docShareModalIsOpen && (
        <div className="modal modal-open" style={{ zIndex: 105 }}>
          <div className="modal-box w-96">
            <h3 className="font-bold text-lg">文档分享</h3>
            <form onSubmit={handleDocShareSubmit} className="py-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">链接 (URL)</span>
                </label>
                <input type="text" placeholder="https://" className="input input-bordered" value={docShareData.url} onChange={(e) => setDocShareData({ ...docShareData, url: e.target.value })} required />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">名称</span>
                </label>
                <input type="text" className="input input-bordered" value={docShareData.name} onChange={(e) => setDocShareData({ ...docShareData, name: e.target.value })} required />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">内容</span>
                </label>
                <textarea className="textarea textarea-bordered" value={docShareData.doc_content} onChange={(e) => setDocShareData({ ...docShareData, doc_content: e.target.value })} required></textarea>
              </div>

              <div className="modal-action">
                <button type="submit" className="btn btn-primary">提交</button>
                <a href="#" className="btn" onClick={() => setDocShareModalIsOpen(false)}>关闭</a>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
}

export default LuboSharePage;
