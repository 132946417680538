import React, { useState, useEffect } from 'react';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

const SonglistPage = () => {
  const openAddModal = () => {
    // 重置所有字段到初始状态
    setNewSong({ title: '', artist: '', language: '', is_paid: '0', gift_id: '' });
    setAddModalOpen(true);
  };
  const closeAddModal = () => setAddModalOpen(false);
  const closeDeleteConfirm = () => setDeleteConfirmOpen(false);
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [newSong, setNewSong] = useState({ title: '', artist: '', language: '', is_paid: 0, gift_id: '' });
  const [songToDelete, setSongToDelete] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [gifts, setGifts] = useState([]);

  const Modal = ({ open, onClose, title, children, onSave }) => {
    if (!open) return null;

    return (
      <div className="modal modal-open">
        <div className="modal-box">
          <h3 className="font-bold text-lg">{title}</h3>
          <div className="py-4">{children}</div>
          <div className="modal-action">
            <div className="flex justify-end space-x-2">
              <button className="btn" onClick={onClose}>关闭</button>
              {onSave && <button className="btn btn-primary" onClick={onSave}>保存更改</button>}
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    // 检查 localStorage 中的 uid 和 session_token
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');

    // 如果不存在，则重定向到 /auth/login
    if (!uid || !sessionToken) {
      navigate('/auth/login');
      return; // 在重定向后终止执行
    }

    // ... 现有的 fetchData 逻辑
  }, [navigate]); 
  const fetchGifts = async () => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    try {
      const response = await fetch('https://v1.vup.name/giftlist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token: sessionToken })
      });
      if (!response.ok) throw new Error('无法加载礼物列表');
      const data = await response.json();
      setGifts(data); // 更新礼物列表状态
    } catch (error) {
      setErrorMessage(error.message);
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    fetchGifts(); // 在组件挂载时获取礼物列表
    fetchSongs(); // 保持原有的歌曲列表获取逻辑
  }, []);

  const openDeleteConfirm = (songId) => {
    setSongToDelete(songId);
    setDeleteConfirmOpen(true);
    setCountdown(5); // 设置倒计时为5秒
    setIsDeleteButtonDisabled(true); // 初始时禁用删除按钮

    const interval = setInterval(() => {
      setCountdown((currentCountdown) => {
        const nextCountdown = currentCountdown - 1;
        if (nextCountdown <= 0) {
          clearInterval(interval);
          setIsDeleteButtonDisabled(false); // 启用删除按钮
          return 0;
        }
        return nextCountdown;
      });
    }, 1000);
  };

  const fetchSongs = async () => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    
    if (!uid || !sessionToken) {
      setError('UID或Session Token未找到');
      return;
    }

    try {
      const response = await fetch('https://v1.vup.name/songlist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, session_token: sessionToken })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || '网络响应错误');
      }

      const data = await response.json();
      setSongs(data.songs);
    } catch (error) {
      setErrorMessage(error.message);
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSongs();
  }, []);

  const handleAddSong = async () => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    const songData = {
      ...newSong,
      gift_id: newSong.is_paid === '0' ? '0' : newSong.gift_id
    };
    try {
      const response = await fetch('https://v1.vup.name/songlist/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid,
          session_token: sessionToken,
          ...songData
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || '添加歌曲失败');
      }

      setSuccessMessage('歌曲添加成功！');
      setShowSuccessModal(true);
      await fetchSongs(); // 重新获取歌曲列表
      closeAddModal();
    } catch (error) {
      setErrorMessage(error.message);
      setShowErrorModal(true);
    }
  };

  const handleDeleteSong = async () => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');

    try {
      const response = await fetch('https://v1.vup.name/songlist/delete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid,
          session_token: sessionToken,
          song_id: songToDelete
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || '删除歌曲失败');
      }

      setSuccessMessage('歌曲删除成功！');
      setShowSuccessModal(true);
      await fetchSongs(); // 重新获取歌曲列表
      setDeleteConfirmOpen(false);
    } catch (error) {
      setErrorMessage(error.message);
      setShowErrorModal(true);
    }
  };

 

  return (
    <div className="bg-slate-100  min-h-screen">
      <NavigationBar />
      {/* 页面内容 */}
      <div className="container mx-auto">
        {/* 歌曲列表 */}
        
        <div className="flex justify-center overflow-x-auto mt-4 mx-4 rounded-lg">
          <button className="btn btn-neutral rounded-lg text-base w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-3/4 relative w-full mt-4" onClick={openAddModal}>
            <FontAwesomeIcon icon={faPlus} /> 添加歌曲
          </button>
        </div>
        <div className="overflow-x-auto mt-4 pb-8 mx-4 rounded-lg">
          <table className="table w-full rounded-lg p-4 max-w-6xl mx-auto bg-white  font-bold text-center w-full ">
            {/* 表头 */}
            <thead>
              <tr className="font-bold text-lg">
                <th>歌曲名</th>
                <th>艺术家</th>
                <th>语言</th>
                <th>是否付费</th>
                <th>礼物ID</th>
                <th>礼物信息</th>
                <th>操作</th>
              </tr>
            </thead>
            {/* 表内容 */}
            <tbody>
              {songs.map((song) => (
                <tr key={song.id} className="text-base">
                  <td>{song.title}</td>
                  <td>{song.artist}</td>
                  <td>{song.language}</td>
                  <td>{song.is_paid ? '是' : '否'}</td>
                  <td>{song.gift_id}</td>
                  <td>
              {song.gift_info ? (
                    <div className="flex md:flex-row flex-col items-center justify-center gap-3">
                      <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                          <img src={`https://v1.vup.name/proxy/avatar?url=${song.gift_info.webp}`} alt="Gift" />
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">{song.gift_info.gift_name}</div>
                        <div className="text-sm opacity-50">价值: {song.gift_info.gift_value} {song.gift_info.type}</div>
                      </div>
                    </div>
                  ) : 'Free'}
              </td>
                  <td>
                    <button className="text-red-500" onClick={() => openDeleteConfirm(song.id)}><FontAwesomeIcon icon={faTrash} className="text-red-500 mr-1"/>删除</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        
      </div>
  
      {/* 添加歌曲模态框 */}
      {addModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg mb-3">添加歌曲</h3>
            <input type="text" className="input input-bordered w-full my-2" placeholder="歌曲名" value={newSong.title} onChange={(e) => setNewSong({ ...newSong, title: e.target.value })} />
            <input type="text" className="input input-bordered w-full my-2" placeholder="艺术家" value={newSong.artist} onChange={(e) => setNewSong({ ...newSong, artist: e.target.value })} />
            <input type="text" className="input input-bordered w-full my-2" placeholder="语言" value={newSong.language} onChange={(e) => setNewSong({ ...newSong, language: e.target.value })} />
            <select className="select select-bordered w-full my-2"
                    value={newSong.is_paid}
                    onChange={(e) => setNewSong({ 
                      ...newSong, 
                      is_paid: e.target.value, 
                      gift_id: e.target.value === '0' ? '0' : newSong.gift_id 
                    })}>
              <option value="0">免费</option>
              <option value="1">付费</option>
            </select>
            {newSong.is_paid === "1" && (
              <select className="select select-bordered w-full my-2"
                      value={newSong.gift_id}
                      onChange={(e) => setNewSong({ ...newSong, gift_id: e.target.value })}>
                {gifts.map(gift => (
                  <option key={gift.gift_info.gift_id}
                          value={gift.gift_info.gift_id}>
                    {gift.gift_info.gift_name}（{gift.gift_info.gift_value} {gift.gift_info.type}）
                  </option>
                ))}
              </select>
            )}
            <div className="modal-action">
              <button className="btn" onClick={closeAddModal}>关闭</button>
              <button className="btn btn-primary" onClick={handleAddSong}>添加</button>
            </div>
          </div>
        </div>
      )}
  
      {/* 删除确认模态框 */}
      {deleteConfirmOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg mb-3">确认删除</h3>
            <p>该操作不可逆！您确定要删除这首歌吗？</p>
            <div className="modal-action">
              <button className="btn" onClick={closeDeleteConfirm}>取消</button>
              <button className={`btn btn-error text-white ${isDeleteButtonDisabled ? 'btn-disabled' : ''}`} 
                      onClick={handleDeleteSong} 
                      disabled={isDeleteButtonDisabled}>
                        <FontAwesomeIcon icon={faTrash} />
                {isDeleteButtonDisabled ? `请等待 ${countdown} 秒` : '删除'}
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* 成功提示模态框 */}
      {showSuccessModal && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg mb-3">操作成功</h3>
            <p>{successMessage}</p>
            <div className="modal-action">
              <button className="btn" onClick={() => setShowSuccessModal(false)}>确定</button>
            </div>
          </div>
        </div>
      )}
      {showErrorModal && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">错误</h3>
            <p className="py-4">{errorMessage}</p>
            <div className="modal-action">
              <button className="btn" onClick={() => setShowErrorModal(false)}>关闭</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
  
      }
  

export default SonglistPage;
