import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Disclosure, Menu, Transition, Dialog } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import Markdown from 'markdown-to-jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPoo,faBell, faFire, faExclamationCircle, faFile, faUser, faReply, faStopwatch, faGear, faPenToSquare, faTriangleExclamation, faBan } from '@fortawesome/free-solid-svg-icons';
import Watermark from '../pages/Watermark';
import DisableDevtool, { DetectorType } from 'disable-devtool';

Modal.setAppElement('#root'); 

const navigation = [
  { name: '首页', href: '/', current: true },
  { name: '星光', href: '/star', current: false },
  { name: '直播信息', href: '/live', current: false },
  { name: '结算信息', href: '/settlement', current: false },
  { name: '福利中心', href: '/task', current: false },
  { name: '资源申请', href: '/resource', current: false },
  {
    name: '主播联动',
    href: '/liandong',
    current: false,
    children: [
      { name: '我的活动', href: '/liandong/my', current: false },
    ],
  },
  {
    name: '舰长管理',
    href: '/captain/activity',
    current: false,
    children: [
      { name: '活动管理', href: '/captain/activity', current: false },
      { name: '舰长导入', href: '/captain/import', current: false },
      { name: '地址管理', href: '/captain/frontend', current: false },
    ],
  },
  {
    name: '直播录像',
    href: '/lubo',
    current: false,
    children: [
      { name: '分享管理', href: '/lubo/share', current: false },
    ],
  },
  { name: '歌单', href: '/songlist', current: true },
  {
    name: '其它',
    href: '',
    current: false,
    children: [
      { name: '积分商城', href: 'https://vup.news', current: false },
      { name: '问答社区', href: 'https://forum.vup.news', current: false },
      { name: '知识维基', href: 'https://vup.name', current: false },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [userFace, setUserFace] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 新增状态
  const location = useLocation(); // 获取当前位置
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [open, setOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [ldMessages, setLdMessages] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');
  const [openSubmenu, setOpenSubmenu] = useState({});
  const [openSubmenus, setOpenSubmenus] = useState({});


  const toggleSubmenu = (submenuName) => {
    setOpenSubmenus(prev => ({
      ...prev,
      [submenuName]: !prev[submenuName]
    }));
  };

  const openModal = async (messageId) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');

    if (uid && sessionToken) {
      try {
        const response = await fetch('https://v1.vup.name/messageid', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            session_token: sessionToken,
            uid: uid,
            id: messageId,
          }),
        });
        const data = await response.json();
        if (data) {
          setSelectedMessage(data);
          setModalIsOpen(true);
        }
      } catch (error) {
        console.error('Error fetching message details:', error);
      }
    }
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const markMessageAsRead = async () => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    const messageId = selectedMessage?.message_id;
  
    if (uid && sessionToken && messageId) {
      try {
        const response = await fetch('https://v1.vup.name/messageread', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            session_token: sessionToken,
            uid: uid,
            id: messageId,
            read: 'true',
          }),
        });
        const data = await response.json();
        if (data.msg) {
          // 更新消息状态
          setResponseMessage(data.msg);
          // 关闭模态框
          setModalIsOpen(false);
          // 刷新页面
          window.location.reload();
        } else if (data.error) {
          setResponseMessage(data.error);
        }
      } catch (error) {
        console.error('Error marking message as read:', error);
      }
    }
  };
  
  useEffect(() => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    setIsLoggedIn(!!uid && !!sessionToken);
    
    const fetchMessages = async () => {
      const uid = localStorage.getItem('uid');
      const sessionToken = localStorage.getItem('session_token');
    
      if (uid && sessionToken) {
        try {
          const response = await fetch('https://v1.vup.name/message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid, session_token: sessionToken }),
          });
          const data = await response.json();
    
          if (data.messages) {
            setMessages(data.messages);
            // 更新通知数量
            setNotificationCount(data.num);
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      }
    };
    
    const fetchMessagesAndCount = async () => {
      const uid = localStorage.getItem('uid');
      const sessionToken = localStorage.getItem('session_token');
    
      if (uid && sessionToken) {
        try {
          const responseMessages = await fetch('https://v1.vup.name/message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid, session_token: sessionToken }),
          });
          const dataMessages = await responseMessages.json();
    
          const responseLdMessages = await fetch('https://v1.vup.name/ldmessage', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid, session_token: sessionToken }),
          });
          const dataLdMessages = await responseLdMessages.json();
    
          if (dataMessages.messages) {
            setMessages(dataMessages.messages);
          }
    
          if (dataLdMessages.ldmsg) {
            setLdMessages(dataLdMessages.ldmsg);
          }
    
          const totalNum = (dataMessages.num || 0) + (dataLdMessages.num || 0);
          setNotificationCount(totalNum);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      
    };
    
  
    fetchMessagesAndCount();
  
    // ... 其他代码 ...
  }, []);
  
  const handleReadMessage = async (ldId, commentId) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
  
    if (uid && sessionToken) {
      try {
        await fetch('https://v1.vup.name/ldmessageread', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            uid: uid,
            session_token: sessionToken,
            ld_id: ldId,
            comment_id: commentId,
            read: 'true',
          }),
        });
        window.location.reload();
      } catch (error) {
        console.error('Error sending read message request:', error);
      }
    }
  };
  
  const uid = localStorage.getItem('uid');
  const name = localStorage.getItem('username');

  const commentMessage = () => {
    return ldMessages.map((msg) => (
      <div key={msg.ld_id} className="overflow-hidden relative max-w-lg mx-auto bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex items-center gap-6 dark:bg-slate-800 dark:highlight-white/5 m-5">
        {/* 头像 */}
        <img className="absolute w-32 h-32  -left-10 md:w-28 md:h-28  md:-left-7 rounded-full shadow-lg" src={`https://v1.vup.name/proxy/avatar?url=${msg.face}`} alt="User" />
        
        {/* 消息内容 */}
        <div className="flex flex-col py-1  pl-24 w-full md:">
          <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">
          <span className="font-bold text-red-500 opacity-90"><FontAwesomeIcon icon={faFile} className="mr-0.5 w-3 h-3 " />{msg.ld_id} </span><span className="font-bold">{msg.uname}</span>
          </strong>
          <span className="text-slate-500 text-sm font-medium dark:text-slate-400">
            {msg.comment_content.length > 20 ? `${msg.comment_content.substring(0, 20)}...` : msg.comment_content}
            </span>
          <div className="chat-footer bg-gray-100 p-1 px-1 rounded-lg opacity-50 text-xs text-gray-500 break-word break-all">
            <span className="text-slate-500 text-sm font-medium dark:text-slate-600">
              <FontAwesomeIcon icon={faReply} className="scale-x-[-1] scale-y-[-1] opacity-80 mr-2"/>
              {msg.content.length > 40 ? `${msg.content.substring(0, 30)}...` : msg.content}
            </span>
          </div>
          <span className="text-slate-500 text-sm font-medium dark:text-slate-400">
            <FontAwesomeIcon icon={faStopwatch} className="mr-1 opacity-50" />
            {msg.time}
          </span>
        </div>
  
        {/* 关闭按钮 */}
        <div className="p-1 pr-6">
          <button 
            className="btn btn-circle btn-outline btn-xs" 
            onClick={() => handleReadMessage(msg.ld_id, msg.comment_id)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    ));
  };
  

  useEffect(() => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    setIsLoggedIn(!!uid && !!sessionToken);

    const fetchMessages = async () => {
      if (uid && sessionToken) {
        try {
          const response = await fetch('https://v1.vup.name/message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid, session_token: sessionToken }),
          });
          const data = await response.json();
          if (data.messages) {
            setMessages(data.messages);
            setNotificationCount(prevCount => prevCount + data.num);
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      }
    };

    fetchMessages();

    const face = localStorage.getItem('face');
    if (face) {
      setUserFace(`https://v1.vup.name/proxy/avatar?url=${face}`);
    }

    navigation.forEach(item => {
      item.current = location.pathname === item.href;
    });
  }, [location]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  
  const toggleSlideOver = () => {
    setShowSlideOver(!showSlideOver);
  };
  return (
    
    <Disclosure as="nav" className="bg-white shadow-lg text-gray-800">
      
      {({ open }) => (
        <>
        <Watermark uid={uid} name={name} />
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="https://s2.loli.net/2024/01/30/EhXnfol8qtwkzK3.png"
                    alt="logo"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    <div key={item.name} className={classNames(
                      item.current ? 'border-b-2 border-blue-500 text-gray-600 rounded-t-lg hover:bg-gray-100 hover:text-black' : 'text-gray-600 rounded-lg hover:bg-gray-100 hover:text-black',
                      'px-3 py-2 text-sm font-bold relative group'
                    )}>
                      <a
                        href={item.href}
                        aria-current={item.current ? 'page' : undefined}
                        className="flex items-center"
                      >
                        {item.name}
                      </a>
                      {item.children && (
                        <div className="absolute left-0 top-full bg-white shadow rounded-md py-1 min-w-max hidden group-hover:block z-50"> {/* 添加 z-50 来确保高 z-index */}
                          {item.children.map((child) => (
                            <a
                              key={child.name}
                              href={child.href}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 whitespace-nowrap"
                            >
                              {child.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>


              </div>
               <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
               <button
                  type="button"
                  className="relative rounded-full bg-gray-100 p-1 text-gray-800 hover:text-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-gray-300"
                  onClick={toggleSlideOver}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    className={`h-5 w-5 ${notificationCount > 0 ? 'shake' : ''}`}
                    aria-hidden="true"
                    style={{ color: "#000000", fontWeight: "bold" }}
                  />
                  {notificationCount > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center rounded-full h-4 w-4 bg-red-600 text-white text-xs font-bold">
                      {notificationCount}
                    </span>
                  )}
                </button>

                {showSlideOver && (
                    <Transition.Root show={showSlideOver} as={Fragment}>
                      <Dialog as="div" className="relative z-10" onClose={setOpen} onClick={() => setShowSlideOver(false)}>
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-300 sm:duration-500"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-300 sm:duration-500"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        
                      >
                        <div 
                          className="fixed inset-0 backdrop-blur-md bg-Neutral-100 bg-opacity-75 transition-opacity"
                          
                        />
                    </Transition.Child>

                      <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                              as={Fragment}
                              enter="transform transition ease-in-out duration-500 sm:duration-700"
                              enterFrom="translate-x-full"
                              enterTo="translate-x-0"
                              leave="transform transition ease-in-out duration-500 sm:duration-700"
                              leaveFrom="translate-x-0"
                              leaveTo="translate-x-full"
                            >
                              <Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
                                <Transition.Child
                                  as={Fragment}
                                  enter="ease-in-out duration-500"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="ease-in-out duration-500"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                    <button
                                      type="button"
                                      className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                      onClick={() => setShowSlideOver(false)}
                                    >
                                      <span className="absolute -inset-2.5" />
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                  </div>
                                </Transition.Child>
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                  <div className="px-4 sm:px-3">
                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                    提醒
                                    </Dialog.Title>
                                    <div className="px-4 sm:px-3">
                                      {messages.map((message) => (
                                        <div 
                                        key={message.message_id} 
                                        role="alert" 
                                        className="alert mt-4 mb-4 cursor-pointer" 
                                        onClick={() => openModal(message.message_id)}
                                      >
                                          <FontAwesomeIcon icon={faBell} />
                                          <div>
                                            <h3 className="font-bold">{message.message_name}</h3>
                                            <div className="text-xs">{message.message_introduce}</div>
                                          </div>
                                          <button className="btn btn-sm btn-neutral btn-outline">查看</button>
                                        </div>
                                      ))}
                                      {commentMessage()}
                                    </div>

                                  </div>
                                  <div className="relative mt-6 flex-1 px-4 sm:px-6">{/* Your content */}</div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                      </Transition.Root>
                    )}
                <div className={`modal ${modalIsOpen ? 'modal-open' : ''}`}>
                  <div className="modal-box">
                    <h3 className="font-bold">{selectedMessage?.message_name}</h3>

                    {/* 使用 react-markdown 渲染 Markdown 内容 */}
                    <div className="prose">
                      <ReactMarkdown>{selectedMessage?.message_content}</ReactMarkdown>
                    </div>

                    <div className="modal-action">
                      <button onClick={closeModal} className="btn">
                        关闭
                      </button>
                      <button onClick={markMessageAsRead} className="btn btn-primary">
                        朕已阅
                      </button>
                    </div>
                  </div>
                </div>
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm text-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-gray-300">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                  className="h-8 w-8 rounded-full"
                  src={userFace || "https://s2.loli.net/2023/12/25/a6XxwkUTFosIJqB.jpg"} // 默认头像
                  alt="User"
                />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-gray-800">
                      {isLoggedIn ? (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              onClick={handleLogout}
                            >
                              退出登录
                            </a>
                          )}
                        </Menu.Item>
                      ) : (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/auth/register"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                注册
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/auth/login"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                登录
                              </a>
                            )}
                          </Menu.Item>
                        </>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <div key={item.name}>
                  <Disclosure.Button
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-300 text-black' : 'text-gray-600 hover:bg-gray-300 hover:text-black',
                      'block px-3 py-2 rounded-md text-base font-bold' // 确保 font-bold 在这里
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                  {item.children && (
                    <div className="space-y-1">
                      {item.children.map((child) => (
                        <a
                          key={child.name}
                          href={child.href}
                          className="block pl-8 pr-4 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white flex items-center"
                        >
                          <span className="mr-2">→</span> {/* 箭头图标 */}
                          {child.name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Disclosure.Panel>
          
        </>
        
      )}
      
    </Disclosure>
  )
}