import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../pages/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCopy, faEye } from '@fortawesome/free-solid-svg-icons';

const FrontendPage = () => {
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [captains, setCaptains] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');
  
      if (!session_token || !uid) {
          navigate('/auth/login');
      } else {
          fetchTypeList();
          fetchCaptainInfo();
      }
  }, [navigate]);

  
    // 获取类型列表
    const fetchTypeList = async () => {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');
  
      if (!session_token || !uid) {
        console.error("Session token or UID not found");
        return;
      }
  
      try {
        const response = await fetch('https://v1.vup.name/typelist', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ session_token, uid }),
        });
        const data = await response.json();
        setTypeList(data.list || []);
      } catch (error) {
        console.error("Error fetching type list:", error);
      }
    };
  
    // 获取舰长信息
    const fetchCaptainInfo = async () => {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');
  
      if (!session_token || !uid) {
        console.error("Session token or UID not found");
        return;
      }
  
      try {
        const response = await fetch('https://v1.vup.name/CaptainInfo/list', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ session_token, uid }),
        });
        const data = await response.json();
        setCaptains(data.captains || []);
      } catch (error) {
        console.error("Error fetching captain info:", error);
      }
    };
    const handleDelete = async (captainUID) => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
      
        try {
          const response = await fetch('https://v1.vup.name/SubmissionInfo/delete', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              session_token,
              uid,
              captainUID,
            }),
          });
      
          const data = await response.json();
          if (data.error) {
            console.error("Error deleting captain:", data.error);
          } else {
            console.log("Captain deleted successfully", data);
            // 更新舰长列表来反映删除操作
            setCaptains(captains.filter(captain => captain.captainUID !== captainUID));
          }
        } catch (error) {
          console.error("Error while deleting captain:", error);
        }
      };
    // 过滤舰长信息
    const filteredCaptains = captains.filter(captain => captain.type === selectedType);

    return (
      <div className="bg-slate-100  min-h-screen">
      <NavigationBar />
      <div className="container mx-auto p-4 flex flex-col items-center">
        <div className="mt-4 p-2 rounded-xl bg-white shadow-md w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-3/5 relative w-full">
        <div className="container mx-auto p-4">
          {/* 类型筛选器 */}
          <div className="mb-4">
            <select onChange={(e) => setSelectedType(e.target.value)} className="select select-bordered w-full">
              <option value="">请选择地址收集活动</option>
              {typeList.map((type) => (
                <option key={type.type} value={type.type}>{type.type_name}</option>
              ))}
            </select>
          </div>
    
          {/* 舰长信息表格 */}
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th className="text-center">舰长UID</th>
                  <th className="text-center">收件人</th>
                  <th className="text-center">舰长</th>
                  <th className="text-center">提督</th>
                  <th className="text-center">总督</th>
                  <th className="text-center">收货人</th>
                  <th className="text-center">手机号</th>
                  <th className="text-center">收货地址</th>
                  <th className="text-center">备用地址</th>
                  <th className="text-center">编辑</th>
                  {/* 其他需要的列 */}
                </tr>
              </thead>
              <tbody>
                {filteredCaptains.map((captain, index) => (
                  <tr key={index}>
                    <td className="text-center">{captain.captainUID}</td>
                    <td className="text-center">{captain.name}</td>
                    <td className="text-center">{captain.jianzhang}</td>
                    <td className="text-center">{captain.tidu}</td>
                    <td className="text-center">{captain.zongdu}</td>
                    <td className="text-center">{captain.contactPerson}</td>
                    <td className="text-center">{captain.contactPhone}</td>
                    <td className="text-center">{captain.Address}</td>
                    <td className="text-center">{captain.shippingAddress}</td>
                    <td className="text-center">

                   
                    <button className="btn btn-error m-1 text-white" onClick={() => handleDelete(captain.captainUID)}>
                    <FontAwesomeIcon icon={faTrash} className="mr-1 text-white" /> 删除
                  </button>
                    </td>
                    {/* 其他列的数据 */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        </div>
        </div>
        </div>
      );
    };
    
    export default FrontendPage;