import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faExclamationCircle, faSpinner, faBan } from '@fortawesome/free-solid-svg-icons';

const StarListPage = () => {
    const [posts, setPosts] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [time, setTime] = useState('');
    const [content, setContent] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedTypeName, setSelectedTypeName] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState('');
    const [sortField, setSortField] = useState('time'); // 默认按时间排序
    const [sortOrder, setSortOrder] = useState('asc'); // 默认正序

    const navigate = useNavigate();

    useEffect(() => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        if (!session_token || !uid) {
            navigate('/auth/login');
        } else {
            fetchTypeList();
            fetchPosts();
        }
    }, [navigate]);

    const fetchTypeList = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');

        try {
            const response = await axios.post('https://v1.vup.name/startype', {
                session_token,
                uid
            });
            setTypeList([ ...response.data]);
        } catch (error) {
            console.error("Error fetching type list:", error);
        }
    };
    const onFilterChange = () => {
        applyFiltersAndSort();
    };
    const handleTypeChange = (e) => {
        setSelectedTypeName(e.target.value);
        applyFiltersAndSort(e.target.value, selectedState, sortField, sortOrder);
    };
    const applyFiltersAndSort = (typeName, state, field, order) => {
        let updatedPosts = [...posts];  // 复制原始帖子列表
    
        // 应用筛选条件
        if (typeName && typeName !== '') {
            updatedPosts = updatedPosts.filter(post => post.t_name === typeName);
        }
        if (state) {
            updatedPosts = updatedPosts.filter(post => post.state.toString() === state);
        }
    
        // 应用排序
        updatedPosts.sort((a, b) => {
            let fieldA = a[sortField];
            let fieldB = b[sortField];
    
            if (sortField === 'time') {
                fieldA = new Date(fieldA);
                fieldB = new Date(fieldB);
            }
    
            return sortOrder === 'asc' ? (fieldA > fieldB ? 1 : -1) : (fieldA < fieldB ? 1 : -1);
        });
    
        setPosts(updatedPosts);  // 更新帖子列表
    };
    
    // 在筛选或排序选择变化时调用 applyFiltersAndSort
    useEffect(() => {
        applyFiltersAndSort();
    }, [selectedTypeName, selectedState, sortField, sortOrder]);
    
    const fetchPosts = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            const response = await axios.post('https://v1.vup.name/starlist', {
                session_token,
                uid
            });
            
            let filteredPosts = response.data;
    
            // 如果设置了type_name筛选条件
            if (selectedTypeName) {
                filteredPosts = filteredPosts.filter(post => post.t_name === selectedTypeName);
            }
            // 如果设置了state筛选条件
            if (selectedState) {
                filteredPosts = filteredPosts.filter(post => post.state.toString() === selectedState);
            }
    
            // 对帖子进行排序
            filteredPosts.sort((a, b) => {
                let fieldA = a[sortField];
                let fieldB = b[sortField];
    
                // 如果是按时间排序，转换为日期对象进行比较
                if (sortField === 'time') {
                    fieldA = new Date(fieldA);
                    fieldB = new Date(fieldB);
                }
    
                // 根据排序顺序进行比较
                if (sortOrder === 'asc') {
                    return fieldA > fieldB ? 1 : -1;
                } else {
                    return fieldA < fieldB ? 1 : -1;
                }
            });
    
            // 解码内容
            const decodedPosts = filteredPosts.map(post => ({
                ...post,
                content: decodeURIComponent(unescape(post.content))
            }));
            
            setPosts(decodedPosts);
        } catch (error) {
            console.error("Error fetching posts:", error);
            if (error.response && error.response.data) {
                setAlertModalContent(error.response.data.error);
                setShowAlertModal(true);
            }
        }
    };

    const handleSubmit = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            await axios.post('https://v1.vup.name/star', {
                session_token,
                uid,
                type_id: selectedType,
                time,
                content
            });
            setShowModal(false);
            // Re-fetch posts to update the list
            await fetchPosts();
        } catch (error) {
            console.error("Error submitting post:", error);
            if (error.response && error.response.data) {
                setAlertModalContent(error.response.data.error);
                setShowAlertModal(true);
            }
        }
    };
    
    const getStatusIcon = (state) => {
        switch (state) {
            case 0: return <FontAwesomeIcon icon={faSpinner} className="animate-spin" />;
            case 1: return <FontAwesomeIcon icon={faCheckCircle} />;
            case 2: return <FontAwesomeIcon icon={faTimesCircle} />;
            case 3: return <FontAwesomeIcon icon={faExclamationCircle} />;
            case 4: return <FontAwesomeIcon icon={faBan} />;
            default: return null;
        }
    };

    const getStatusColor = (state) => {
        switch (state) {
            case 0: return 'bg-yellow-500 shadow-yellow-500/30';
            case 1: return 'bg-green-500 shadow-green-500/30';
            case 2: return 'bg-red-500 shadow-red-500/30';
            case 3: return 'bg-blue-500 shadow-blue-500/30';
            case 4: return 'bg-gray-500 shadow-gray-500/50';
            default: return 'bg-gray-300 shadow-gray-300/50';
        }
    };
    const renderComments = (post) => {
        if (post.astatus === 1 && post.comments) {
            return (
                <div role="alert" className="alert alert-warning shadow-lg mt-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" stroke="white" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <div>
                    <h3 className="font-bold text-white">{post.comments}</h3>
                </div>
                </div>
            );
        }
        return null;
    };
    useEffect(() => {
        fetchPosts();
    }, [selectedTypeName, selectedState]);
    
    const getStatusText = (state) => {
        const statusMap = {
            0: '待审核',
            1: '审核通过',
            2: '审核失败',
            3: '复审',
            4: '拒绝'
        };
        return statusMap[state] || '未知状态';
    };

    return (
        <div className="bg-slate-100  min-h-screen">
            <NavigationBar />
            
            <div className="container mx-auto flex flex-col items-center  p-5 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-7/8 relative w-full">
            <button className="btn btn-active btn-neutral mt-2 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-3/5 relative w-full" onClick={() => setShowModal(true)}>申请资源</button>
                {/* 筛选 type_name */}
                <div className="form-control mt-4 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-3/5 relative w-full">

                <div className="flex flex-col sm:flex-row gap-2">
                    {/* 排序字段选择 */}
                <select className="select select-bordered w-full" value={sortField} onChange={(e) => setSortField(e.target.value)}>
                    <option value="time">按时间</option>
                    <option value="star_id">按ID</option>
                </select>

                {/* 排序顺序选择 */}
                <select className="select select-bordered w-full" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                    <option value="asc">正序</option>
                    <option value="desc">倒序</option>
                </select>
                <select className="select select-bordered w-full" value={selectedTypeName} onChange={handleTypeChange}>
                    <option value="">选择申请类型</option>
                    {typeList.map((type) => (
                        <option key={type.type_id} value={type.type_name}>{type.type_name}</option>
                    ))}
                </select>


                {/* 筛选 state */}
                <select className="select select-bordered w-full" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                    <option value="">选择状态</option>
                    <option value="0">待审核</option>
                    <option value="1">审核通过</option>
                    <option value="2">审核失败</option>
                    <option value="3">复审</option>
                    <option value="4">拒绝</option>
                </select>
                </div>
                </div>
                {posts.map(post => (
                <div key={post.star_id} className="mt-4 p-5 rounded-lg bg-white shadow-md w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-3/5 relative w-full">
                    <div className={`absolute top-2 left-2 rounded-full px-3 py-1 text-white ${getStatusColor(post.state)} shadow-lg`}>
                        {getStatusIcon(post.state)}
                        <span className="ml-2 font-bold">{getStatusText(post.state)}</span>
                    </div>
                    <div className="absolute top-2 shadow-green-500 right-2 rounded-full px-3 py-1 text-white font-bold bg-green-500 ">
                    <div className="tooltip tooltip-bottom" data-tip="开始时间">
                        <span>{post.time}</span>
                    </div>
                    </div>
                    
                    <div className="card-body mt-8 bg-gray-100 rounded-lg p-5">
                    {renderComments(post)}
                            <div className="prose ">
                                
                                <h2 className="text-violet-600 font-bold">#{post.star_id} {post.t_name}</h2>
                                
                                <Markdown>{post.content}</Markdown>
                            </div>
                        </div>
                        
                    </div>
                ))}

                {showModal && (
                    <div className="modal modal-open">
                        <div className="modal-box">
                        <span className="text-xl font-bold">资源申请</span>
                            <div className="form-control">
                                <label className="label">
                                    
                                </label>
                                <select className="select select-bordered w-full" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                    {typeList.map((type) => (
                                        <option key={type.type_id} value={type.type_id}>{type.type_name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">选择使用时间</span>
                                </label>
                                <input type="datetime-local" className="input input-bordered w-full" value={time} onChange={(e) => setTime(e.target.value)} />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">填写申请理由（支持Markdown）</span>
                                </label>
                                <textarea className="textarea textarea-bordered w-full" value={content} onChange={(e) => setContent(e.target.value)} />
                            </div>

                            <div className="modal-action">
                                <button className="btn btn-active btn-neutral" onClick={handleSubmit}>确认申请</button>
                                <button className="btn" onClick={() => setShowModal(false)}>关闭</button>
                            </div>
                        </div>
                    </div>
                )}
                {showAlertModal && (
                    <div className="modal modal-open">
                        <div className="modal-box">
                            <p className="font-bold">{alertModalContent}</p>
                            <div className="modal-action">
                                <button className="btn" onClick={() => setShowAlertModal(false)}>关闭</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StarListPage;
