import React, { useState } from 'react';
import '../App.css';

const RegisterForm = () => {
  const [uid, setUid] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false); 
  const [errorMessage, setErrorMessage] = useState('');

  const handleUidChange = (e) => {
    const value = e.target.value;
    // Check if the value is numeric or empty (allowing backspace to clear)
    if (/^\d*$/.test(value)) {
      setUid(value);
      setErrorMessage(''); // Clear error message if the input is valid
    } else {
      // Set error message if the input is not purely numeric
      setErrorMessage('请输入纯数字UID！');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://v1.vup.name/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid, password }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || '发生未知错误，请稍后再试。');
      }

      
      // 处理返回的数据，例如存储到 localStorage
      localStorage.setItem('session_token', data.session_token);
      localStorage.setItem('uid', data.uid);
      localStorage.setItem('username', data.username);
      localStorage.setItem('face', data.face);
      // 重定向到其他页面
      window.location.href = '/';
    } catch (error) {
      setErrorMessage(error.message); // 设置错误信息
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false); // 关闭模态框
    setErrorMessage(''); // 重置错误信息
  };

  return (
    <div className="bg-cover bg-[url('/public/bg.jpg')]" >
    <div className="centered-card">
      <div className="mt-4 bg-opacity-5 backdrop-blur-xl p-5 rounded-lg bg-black shadow-md w-10/12 sm:w-5/12 md:w-4/12 lg:w-4/12 xl:w-2/12">
      <div className="flex justify-center">
        <img src="/logo-w.png" alt="Logo" className="my-auto" />
      </div>
      <div role="alert" className="alert alert-error mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" style={{color: "#ffffff",}} viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <span className="text-white font-bold">服务中心账户与积分商城不通用！！！</span>
      </div>
      <div role="alert" className="alert alert-warning mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <span className="font-bold">别用邮箱登录了！！！😭<br></br>登陆账号是您的B站UID不是邮箱！！😡</span>
      </div>
        <form onSubmit={handleSubmit}>
              <label className="form-control w-full my-1">
                <div className="label">
                  <span className="label-text font-bold text-white">UID</span>
                </div>
                <input
                  type="text"
                  value={uid}
                  onChange={handleUidChange}
                  placeholder="请输入您B站的UID"
                  className="input input-bordered input-primary w-full "
                />
              </label>
              {errorMessage && <div className="text-red-500">{errorMessage}</div>}
              <label className="form-control w-full my-4">
                <div className="label">
                  <span className="label-text font-bold text-white">密码</span>
                </div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="请输入密码！"
                  className="input input-bordered input-primary w-full"
                />
              </label>
              <button type="submit" className="w-full btn">登录</button>
          </form>
        <a href="/auth/register" className="font-semibold text-white">注册</a>
      </div>
      {showModal && (
            <dialog id="error_modal" className="modal" open>
              <div className="modal-box bg-opacity-10 backdrop-blur-2xl bg-black shadow-md">
                <h3 className="font-bold text-lg text-white">错误</h3>
                <p className="py-4 text-white">{errorMessage}</p>
                <div className="modal-action">
                  <button className="btn" onClick={closeModal}>关闭</button>
                </div>
              </div>
            </dialog>
          )}
    </div>
    </div>
  );
};

export default RegisterForm;
