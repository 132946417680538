import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavigationBar from '../pages/NavigationBar';
import { marked } from 'marked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faBed, faCircle, faBell } from '@fortawesome/free-solid-svg-icons';
import '../App.css';

function HomePage() {
  const [notices, setNotices] = useState([]);
  const [liveInfo, setLiveInfo] = useState(null);
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const sessionToken = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    if (!sessionToken || !uid) {
      window.location.href = '/auth/login';
      return;
    }

    axios.post('https://v1.vup.name/notice', { session_token: sessionToken, uid: uid })
      .then(response => {
        setNotices(response.data.notice);
      })
      .catch(error => {
        console.error('Error fetching notices', error);
      });

    axios.post('https://v1.vup.name/lubo', { session_token: sessionToken, uid: uid })
      .then(response => {
        setLiveInfo(response.data);
        if (response.data.code === 4) {
          updateCountdown(response.data.end_time); // 初始化倒计时
        }
      })
      .catch(error => {
        console.error('Error fetching live info', error);
      });
  }, []);

  useEffect(() => {
    if (liveInfo && liveInfo.code === 4) {
      const timer = setInterval(() => {
        updateCountdown(liveInfo.end_time);
      }, 1000);

      return () => clearInterval(timer); // 清理定时器
    }
  }, [liveInfo]);

  const updateCountdown = (endTime) => {
    const end = new Date(endTime).getTime();
    const now = new Date().getTime();
    const distance = end - now;

    if (distance < 0) {
      setCountdown("时间已过");
      return;
    }

    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setCountdown(`${hours}小时${minutes}分钟${seconds}秒`);
  };
  
  return (
    <div className="bg-slate-100 min-h-screen">
      <NavigationBar />
      <div className="container mx-auto flex flex-col items-center">
      <div className="mt-4 rounded-xl shadow-xl w-11/12 sm:w-4/5 md:w-4/5 lg:w-7/8 xl:w-7/8">
          {liveInfo && liveInfo.code && (
            <div className="flex items-center w-full px-5 py-3 bg-yellow-200 text-yellow-800 rounded-xl">
              <FontAwesomeIcon icon={faBell} className="text-xl" />
              {liveInfo.code === 4 && (
              <div className="ml-4 text-md font-semibold whitespace-nowrap mr-2 "> {/* 保持倒计时文本的紧凑 */}
                有效期:<span className="text-red-500"> {countdown}</span>
              </div>
            )}
              <div className="flex-1 overflow-hidden">
                <div className="whitespace-nowrap scroll-animation">
                  <p className="inline font-bold">{liveInfo.msg}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 pt-3 px-5 rounded-xl bg-white shadow-xl w-11/12 sm:w-5/6 md:w-4/5 lg:w-7/8 xl:w-7/8">
          <h1 className="font-bold mb-4 text-2xl">公告</h1>
          {notices.map(notice => (
            <div key={notice.notice_id} className="bg-slate-100 rounded-2xl p-4 mb-5">
              <h2 className="text-lg font-bold">{notice.notice_name}</h2>
              <div className="prose" dangerouslySetInnerHTML={{ __html: marked(notice.notice_introduce) }}></div>
            </div>
          ))}
        </div>
        <div className="mt-2 grid  w-11/12 float-left sm:w-5/6 md:w-4/5 lg:w-7/8 xl:w-7/8 mx-auto">
        {liveInfo && (typeof liveInfo.code === 'undefined' || liveInfo.code === 4) && liveInfo.room_info && (
          <div className="mt-2 w-full sm:w-1/3 float-left">
            <div className="bg-white rounded-xl shadow-xl overflow-hidden">
              <div className="relative" style={{ paddingTop: "56.25%" }}> {/* 维持9:16的比例 */}
                <img
                  src={liveInfo.room_info.cover}
                  alt="直播封面"
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  onError={(e) => { e.target.onerror = null; e.target.src = 'https://example.com/default-cover.jpg'; }}
                />
                <h3 className="absolute top-0 left-0 p-2 text-sm text-white font-bold bg-black/50 backdrop-blur-md rounded-br-lg">
                  {liveInfo.room_info.title}
                </h3>
                <div className="absolute top-0 right-0 p-2 text-white font-bold bg-black/50 backdrop-blur-md rounded-bl-lg">
                  {liveInfo.task_status.running_status === 'recording' ? (
                    <FontAwesomeIcon icon={faCamera} beatFade/> 
                  ) : (
                    <FontAwesomeIcon icon={faBed} />
                  )}
                </div>
              </div>

              <div className="p-4 flex items-center">
                <img src={liveInfo.user_info.face} alt="主播头像" className="rounded-full w-16 h-16" />
                <div className="ml-4 flex-1">
                  <div className="flex items-center">
                    <p className="font-bold text-sm flex-shrink-0">{liveInfo.user_info.name}</p>
                    {liveInfo.room_info.live_status === 1 ? (
                      <FontAwesomeIcon icon={faCircle} beat className="text-red-500 ml-2" />
                    ) : (
                      <FontAwesomeIcon icon={faCircle} className="text-gray-500 ml-2" />
                    )}
                  </div>
                  <p className="text-sm mt-1">房间号：{liveInfo.room_info.room_id}</p>
                  <p className="text-sm mt-1 line-clamp-3">{liveInfo.room_info.description}</p>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>

      </div>
    </div>
  );
}

export default HomePage;
