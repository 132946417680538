import React from 'react';
import RegisterForm from './pages/RegisterForm';
import Register from './pages/Register';
import HomePage from './pages/HomePage';
import StarPage from './pages/StarPage';
import LivePage from './pages/LivePage';
import SettlementPage from './pages/SettlementPage';
import CaptionPage from './pages/CaptionPage';
import SubmissionPage from './pages/SubmissionPage';
import VerificationCodePage from './pages/VerificationCodePage';
import FrontendPage from './pages/FrontendPage';
import StarListPage from './pages/StarListPage';
import LiandongPage from './pages/LiandongPage';
import MyLiandongPage from './pages/MyLiandongPage';
import TaskPage from './pages/TaskPage';
import SonglistPage from './pages/SonglistPage';
import BoxlistPage from './pages/BoxlistPage';
import LuboPage from './pages/LuboPage';
import LuboSharePage from './pages/LuboSharePage';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    
    <Router>
        <Routes>
          <Route path="/auth/login" element={<RegisterForm />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/star" element={<StarPage />} />
          <Route path="/live" element={<LivePage />} />
          <Route path="/settlement" element={<SettlementPage />} />
          <Route path="/captain/activity" element={<CaptionPage />} />
          <Route path="/captain/import" element={<SubmissionPage />} />
          <Route path="/verificationcode" element={<VerificationCodePage />} />
          <Route path="/captain/frontend" element={<FrontendPage />} />
          <Route path="/resource" element={<StarListPage />} />
          <Route path="/liandong" element={<LiandongPage />} />
          <Route path="/liandong/my" element={<MyLiandongPage />} />
          <Route path="/task" element={<TaskPage />} />
          <Route path="/songlist" element={<SonglistPage />} />
          <Route path="/box" element={<BoxlistPage />} />
          <Route path="/lubo" element={<LuboPage />} />
          <Route path="/lubo/share" element={<LuboSharePage />} />
        </Routes>
    </Router>
  );
}

export default App;
