import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../pages/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCopy, faEye } from '@fortawesome/free-solid-svg-icons';


const Modal = ({ open, onClose, title, children, onSave }) => {
  if (!open) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">{title}</h3>
        <div className="py-4">{children}</div>
        <div className="modal-action">
          <div className="flex justify-end space-x-2">
            <button className="btn" onClick={onClose}>关闭</button>
            <button className="btn btn-primary" onClick={onSave}>保存更改</button>
          </div>
        </div>
      </div>
    </div>
  );
};
const TypeManagementPage = () => {
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentType, setCurrentType] = useState({});
  const navigate = useNavigate();
  const [captainsData, setCaptainsData] = useState([]);
  const [isCaptainsModalOpen, setIsCaptainsModalOpen] = useState(false);
  const [captainsError, setCaptainsError] = useState('');

  useEffect(() => {
    fetchTypes();
  }, []);

  const handleViewCaptains = async (typeId) => {
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');
  
    try {
      const response = await fetch('https://v1.vup.name/SubmissionInfo/list', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_token, uid, type: typeId }),
      });
  
      if (!response.ok) throw new Error('您还没有创建活动！');
      const data = await response.json();

      if (!Array.isArray(data)) {
        setCaptainsError("没有找到相关记录，请确认是否已创建");
        setIsCaptainsModalOpen(true); // 打开模态框
        return;
      }

      setCaptainsData(data);
      setCaptainsError(''); // 清除错误消息
      setIsCaptainsModalOpen(true);
    } catch (error) {
      setError(error.message);
    }
  };

  

const handleDelete = async (typeId) => {
    setLoading(true);
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    try {
        const response = await fetch('https://v1.vup.name/typelist', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              session_token, 
              uid, 
              type: typeId,
              action: 'delete'  // 添加这个字段来指示删除操作
            }),
        });

        if (!response.ok) throw new Error(`错误: ${response.statusText}`);
        alert('类型成功删除');
        fetchTypes();  // 在成功删除后刷新类型列表
    } catch (error) {
        setError(error.message);
    } finally {
        setLoading(false);
    }
};
  const fetchTypes = async () => {
    setLoading(true);
    try {
      const session_token = localStorage.getItem('session_token');
      const uid = localStorage.getItem('uid');

      if (!session_token) {
        navigate('/auth/login');
        return;
      }

      const response = await fetch('https://v1.vup.name/typelist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_token, uid }),
      });

      if (!response.ok) throw new Error('您还没有创建活动');
      const data = await response.json();
      setTypes(data.list);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (type = null) => {
    setModalOpen(true);
    if (type) {
      setCurrentType(type);
      setEditMode(true);
    } else {
      setCurrentType({});
      setEditMode(false);
    }
  };
  
  const handleCreateOrEdit = async () => {
    setLoading(true);
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    try {
        const payload = {
            uid,
            session_token,
            type_name: currentType.type_name,
            start_time: currentType.start_time,
            end_time: currentType.end_time
        };

        if (editMode) {
            payload.type = currentType.type; // For editing
        }

        const response = await fetch('https://v1.vup.name/typelist', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });

        if (!response.ok) throw new Error(`错误: ${response.statusText}`);

        const data = await response.json();
        alert(data.msg);

        fetchTypes(); // Refresh the types list after successful submission
        window.location.reload(); // Reload the page to reflect the changes

    } catch (error) {
        setError(error.message);
    } finally {
        setLoading(false);
        setModalOpen(false);
    }
};
  
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    const seconds = String(dateTime.getSeconds()).padStart(2, '0');
    return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
  };
  

  const handleExport = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    const headers = "登舰时间,舰长UID,舰长,提督,总督,超时时间,验证码\n";
    csvContent += headers;
  
    captainsData.forEach(row => {
      const rowData = [
        row.boardingTime,
        row.captainUID,
        row.jianzhang,
        row.tidu,
        row.zongdu,
        row.timeout,
        row.verificationCode
      ].join(",");
      csvContent += rowData + "\n";
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "captains_data.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };
  const renderTable = () => {
    return (
      <div className="p-2 rounded-lg bg-white shadow-md transition duration-300 ease-in-out w-full md:w-4/5 lg:w-4/5">
        <table className="table-auto w-full" style={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
          <thead>
            <tr>
              <th className="text-center">活动名称</th>
              <th className="text-center">开始时间</th> {/* 在小屏幕上隐藏 */}
              <th className="text-center">结束时间</th>
              <th className="text-center">操作</th>
            </tr>
          </thead>
          <tbody>
            {types.map((type, index) => (
              <tr key={type.type} className="bg-gray-50 rounded-2xl">
                <td className="text-center rounded-lg rounded-r-none font-bold subpixel-antialiased">{type.type_name}</td>
                <td className="text-center">{formatDateTime(type.start_time)}</td> {/* 在小屏幕上隐藏 */}
                <td className="text-center">{formatDateTime(type.end_time)}</td>
                <td className="text-center rounded-2xl rounded-l-none py-4">
                  <button className="btn btn-sm btn-primary m-1 text-white" onClick={() => openModal(type)}>
                    <FontAwesomeIcon icon={faEdit} className="mr-1 text-white" /> 编辑
                  </button>
                  <button className="btn btn-sm btn-error m-1 text-white" onClick={() => handleDelete(type.type)}>
                    <FontAwesomeIcon icon={faTrash} className="mr-1 text-white" /> 删除
                  </button>
                  <button className="btn btn-sm btn-secondary m-1 text-white" onClick={() => handleViewCaptains(type.type)}>
                  <FontAwesomeIcon icon={faEye} className="mr-1 text-white" />查看
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const CaptainsModal = ({ open, onClose, data, onExport }) => {
    if (!open) return null;
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          // 复制成功的处理，您可以在这里添加通知或其他操作
          console.log('验证码已复制到剪贴板');
        })
        .catch((err) => {
          // 复制失败的处理
          console.error('复制失败:', err);
        });
    };
    const renderTableBody = () => {
      if (captainsError) {
        return (
          <tr>
            <td colSpan="7" className="text-center p-3 bg-gray-100 rounded-lg font-bold">
              {captainsError}
            </td>
          </tr>
        );
      }
      return data.map((item, index) => (
        <tr key={index} className="bg-gray-100 rounded-lg border-b">
          <td className="text-center rounded-l-lg p-3 border-r">{item.boardingTime}</td>
          <td className="text-center p-3 border-r">{item.captainUID}</td>
          <td className="text-center p-3 border-r">{item.jianzhang}</td>
          <td className="text-center p-3 border-r">{item.tidu}</td>
          <td className="text-center p-3 border-r">{item.zongdu}</td>
          <td className="text-center p-3 border-r">{item.timeout}</td>
          <td className="text-center p-3 border-r">
            {item.verificationCode}
            <button
              className="ml-2 text-blue-600 hover:text-blue-800 focus:outline-none"
              onClick={() => copyToClipboard(`${item.captainUID}您好，这是您的舰长礼物兑换验证码：${item.verificationCode}，请在${item.timeout}之前填写代码哦~在${item.timeout}之前您可以无限次修改，在${item.timeout}之后，将不再允许更改收货地址~`)}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </td>
        </tr>
      ));
    };
  
    return (
      <div className="modal modal-open">
        <div className="modal-box w-full max-w-screen-2xl overflow-hidden ">
          <h3 className="font-bold text-lg">舰长信息</h3>
          <div className="py-4 overflow-auto max-h-[600px]"> {/* 调整最大高度 */}
            <table className="table-auto w-full" style={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
              <thead>
                <tr>
                  <th>登舰时间</th>
                  <th>舰长UID</th>
                  <th>舰长</th>
                  <th>提督</th>
                  <th>总督</th>
                  <th>过期时间</th>
                  <th>验证码</th>
                </tr>
              </thead>
              <tbody>
                {renderTableBody()}
              </tbody>
            </table>
          </div>
          <div className="modal-action">
            <button className="btn" onClick={onClose}>关闭</button>
            <button className="btn btn-primary" onClick={onExport}>导出为CSV</button>
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <div className="bg-slate-100  min-h-screen">
      <NavigationBar />
      <div className="container mx-auto p-4 flex flex-col items-center">
      <div className="mb-4">
      <button className="btn btn-neutral mt-8 sm:mt-0 sm:ml-4 w-full" onClick={() => openModal()}>创建新活动</button>
      </div>
        {loading && <div className="flex-grow flex justify-center items-center mt-2">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">Loading...</div>
          </div>
        </div>}
        {error && <div className="flex-grow flex justify-center items-center mt-1">
          <div className="card bg-white shadow-xl p-6 rounded-lg">
            <div className="font-bold">{error}</div>
          </div>
        </div>}
        {!loading && !error && renderTable()}
        {modalOpen && (
          <Modal
            title={editMode ? "编辑活动" : "创建活动"}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSave={handleCreateOrEdit}
          >
            <label className="label"><span className="label-text font-bold">填写活动名称</span></label>
            <input type="text" className="input input-bordered w-full mb-2" placeholder="活动名称" value={currentType.type_name || ''} onChange={(e) => setCurrentType({ ...currentType, type_name: e.target.value })} />
            <label className="label"><span className="label-text font-bold">填写开始时间</span></label>
            <input type="datetime-local" className="input input-bordered w-full mb-2" placeholder="开始时间" value={currentType.start_time || ''} onChange={(e) => setCurrentType({ ...currentType, start_time: e.target.value })} />
            <label className="label"><span className="label-text font-bold">填写结束时间</span></label>
            <input type="datetime-local" className="input input-bordered w-full mb-2" placeholder="结束时间" value={currentType.end_time || ''} onChange={(e) => setCurrentType({ ...currentType, end_time: e.target.value })} />
          </Modal>
        )}
              {isCaptainsModalOpen && (
          <CaptainsModal
            open={isCaptainsModalOpen}
            onClose={() => setIsCaptainsModalOpen(false)}
            data={captainsData}
            onExport={handleExport}
          />
        )}
              </div>
            </div>
          );
        };



export default TypeManagementPage;
