import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../pages/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faFileUpload, faCircleXmark, faCircleCheck, faTrash, faBan, faTrashCan, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';


const SubmissionPage = () => {
  const [submissions, setSubmissions] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [currentEdit, setCurrentEdit] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); 
  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false); 
  const [showEditModal, setShowEditModal] = useState(false);
  const [responseType, setResponseType] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [editingId, setEditingId] = useState(null); 
  const [editingValue, setEditingValue] = useState('');
  const [editState, setEditState] = useState({
    row: null,
    column: null,
    value: ''
  });
  const navigate = useNavigate();
  
  useEffect(() => {
    const isAllSelected = selectedIds.length === submissions.length && submissions.length > 0;
    const isSomeSelected = selectedIds.length > 0 && selectedIds.length < submissions.length;
    if (selectAllRef.current) {
      selectAllRef.current.checked = isAllSelected;
      selectAllRef.current.indeterminate = isSomeSelected;
    }
  }, [selectedIds, submissions.length]);

  useEffect(() => {
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');

    if (!session_token || !uid) {
        navigate('/auth/login');
    } else {
        fetchTypeList();

    }
}, [navigate]);

  const selectAllRef = useRef(null);

  const fetchTypeList = async () => {
    // 从 localStorage 获取 session_token 和 uid
    const session_token = localStorage.getItem('session_token');
    const uid = localStorage.getItem('uid');
  
    // 检查 session_token 和 uid 是否存在
    if (!session_token || !uid) {
      console.error("Session token or UID not found");
      return;
    }
  
    try {
      const response = await fetch('https://v1.vup.name/typelist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_token, uid }),
      });
      const data = await response.json();
      setTypeList(data.list || []); // 确保即使数据中没有 list，也设置为一个空数组
    } catch (error) {
      console.error("Error fetching type list:", error);
      setTypeList([]); // 请求失败时也设置为一个空数组
    }
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: (results) => {
          const submissionsMap = new Map();
  
          results.data.forEach(row => {
            const captainUID = row[1];
            let submission = submissionsMap.get(captainUID) || {
              captainUID: captainUID,
              nickname: row[2], 
              date: selectedDate,
              jianzhang: 0,
              tidu: 0,
              zongdu: 0,
            };
  
            switch (row[0].trim()) {
              case '舰长':
                submission.jianzhang += 1;
                break;
              case '提督':
                submission.tidu += 1;
                break;
              case '总督':
                submission.zongdu += 1;
                break;
              // 可以添加更多职称的处理
            }
  
            submissionsMap.set(captainUID, submission);
          });
  
          setSubmissions(Array.from(submissionsMap.values()));
        }
      });
    }
  };
  
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleEdit = (index) => {
    setEditIndex(index);
    setCurrentEdit({ ...submissions[index] });
    setShowEditModal(true);
  };

  const handleEditChange = (e) => {
    setCurrentEdit({ ...currentEdit, [e.target.name]: e.target.value });
  };

  const saveEdit = () => {
    const updatedSubmissions = [...submissions];
    updatedSubmissions[editIndex] = currentEdit;
    setSubmissions(updatedSubmissions);
    setEditIndex(-1);
    closeEdit(); 
  };
  const closeEdit = () => {
    setShowEditModal(false);
    setEditIndex(-1);
    setCurrentEdit({});
  };
  const closeModal = () => {
    setShowModal(false);
    setModalContent('');
  };
  const handleDelete = (index) => {
    // 使用filter方法创建一个不包含被删除条目的新数组
    const updatedSubmissions = submissions.filter((_, i) => i !== index);
    setSubmissions(updatedSubmissions); // 更新状态
  };
  // 处理单个复选框变化
  const handleCheckboxChange = (index, isChecked) => {
    if (isChecked) {
      setSelectedIds([...selectedIds, index]); // 添加到选中列表
    } else {
      setSelectedIds(selectedIds.filter(id => id !== index)); // 从选中列表移除
    }
  };

  // 处理全选复选框的变化
  const handleSelectAllCheckbox = (isChecked) => {
    if (isChecked) {
      setSelectedIds(submissions.map((_, index) => index)); // 选中所有
    } else {
      setSelectedIds([]); // 清除所有选中
    }
  };

  const handleBatchDelete = () => {
    // 只设置显示模态框，不立即执行删除操作
    if (selectedIds.length > 0) {
      setShowDeleteConfirmModal(true);
    } else {
      // 可选：如果没有选择任何条目，可以显示提示或不执行任何操作
      alert("请选择至少一个条目进行删除。");
    }
  };

  useEffect(() => {
    let countdown;
    if (showDeleteConfirmModal) {
      let seconds = 5;
      countdown = setInterval(() => {
        document.getElementById("countdown").textContent = `${--seconds}`;
        if (seconds <= 0) {
          clearInterval(countdown);
          setShowDeleteConfirmModal(false);
        }
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [showDeleteConfirmModal]);

  const confirmDelete = () => {
    // 只删除那些在selectedIds数组中的条目
    const newSubmissions = submissions.filter((_, index) => !selectedIds.includes(index));
    setSubmissions(newSubmissions);
    setSelectedIds([]);
    // 隐藏模态框
    setShowDeleteConfirmModal(false);
    // 清空文件输入，确保可以重新上传相同文件
    document.getElementById("fileUpload").value = null;
};

  // 处理点击编辑
  const handleEditClick = (rowIndex, columnName, currentValue) => {
    setEditState({
      row: rowIndex,
      column: columnName,
      value: currentValue
    });
  };

  // 处理编辑值改变
  const handleEditInfoChange = (e) => {
    setEditState(prevState => ({
      ...prevState,
      value: e.target.value
    }));
  };

  // 处理编辑完成（失去焦点时）
  const handleEditBlur = () => {
    const updatedSubmissions = submissions.map((submission, index) => {
      if (index === editState.row) {
        return { ...submission, [editState.column]: editState.value };
      }
      return submission;
    });
    
    setSubmissions(updatedSubmissions);
    setEditState({ row: null, column: null, value: '' }); // Reset edit state
  };

  const submitData = async () => {
  const session_token = localStorage.getItem('session_token');
  const uid = localStorage.getItem('uid');

  if (!session_token || !uid) {
    alert("Session token or UID not found");
    return;
  }
  
  const submitSubmissions = submissions.map(({ captainUID, jianzhang, tidu, zongdu }) => ({
    captainUID,
    date: selectedDate,
    jianzhang,
    tidu,
    zongdu
  }));

  const data = {
    session_token,
    uid,
    type: selectedType,
    Captain: submitSubmissions,
    timeout: selectedDate 
  };
  if (!selectedType) {
    setModalContent("在提交之前请选择活动类型！");
    setShowModal(true);
    return; // 提前返回，不执行后续的请求发送逻辑
  }
  try {
    const response = await fetch('https://v1.vup.name/SubmissionInfo/input', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.msg) {
      setModalContent(responseData.msg);
      setResponseType("msg");
    } else if (responseData.error) {
      setModalContent(responseData.error);
      setResponseType("error");
    }

    setShowModal(true);
  } catch (error) {
    console.error("Error submitting data:", error);
    setModalContent(error.toString());
    setResponseType("error");
    setShowModal(true);
  }
};
  return (
    <div className="bg-slate-100  min-h-screen">
      <NavigationBar />
      <div className="container mx-auto p-4 flex flex-col items-center">
        <div className="flex flex-col space-y-4 items-center my-4 w-full">
          {/* 文件上传按钮 */}
          <label className="btn btn-primary flex items-center justify-center w-full">
            <FontAwesomeIcon icon={faFileUpload} style={{color: "#ffffff",}} />
            <span className="text-white font-bold">上传 CSV</span>
            <input type="file" accept=".csv" onChange={handleFileUpload} className="hidden" id="fileUpload" />
          </label>

          {/* 日期选择器 */}
          <div className="tooltip tooltip-bottom w-full" data-tip="请选择提交日期">
            <input 
              type="date" 
              value={selectedDate} 
              onChange={handleDateChange} 
              className="input input-bordered w-full" // 确保宽度为全宽
            />
          </div>

          {/* 类型选择器 */}
          {typeList.length > 0 ? (
          <select onChange={(e) => setSelectedType(e.target.value)} className="input input-bordered w-full" value={selectedType}> // 确保宽度为全宽
              <option value="" disabled selected>请选择活动类型</option>
              {typeList.map((type) => (
                <option key={type.type} value={type.type}>{type.type_name}</option>
              ))}
            </select>
          ) : (
            <p className="w-full text-center">请创建活动！</p>  // 或者显示一个空的 select 元素，并确保宽度为全宽
          )}
        </div>

        {submissions.length > 0 && (
          <div className="bg-base-100 rounded-xl  overflow-x-auto w-full p-4">
            <div className="my-4 flex gap-2 justify-end">
              <button className="btn btn-success text-white" onClick={submitData}>
                <FontAwesomeIcon icon={faArrowUpFromBracket} style={{color: "#ffffff",}} />
                提交数据
              </button>
              <button className="btn btn-error text-white" onClick={handleBatchDelete}>
                <FontAwesomeIcon icon={faTrashCan} style={{color: "#ffffff",}} />
                批量删除
                </button>
            </div>
            <table className="table w-full table-compact ">
            <thead>
              <tr className="bg-base-200 rounded-tl-lg rounded-tr-lg">
                <th className="text-center rounded-tl-lg">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-error"
                    ref={selectAllRef}
                    onChange={(e) => handleSelectAllCheckbox(e.target.checked)}
                    checked={selectedIds.length === submissions.length && submissions.length > 0} 
                  />
                </th>
                <th className="text-center text-lg text-black">舰长UID</th>
                <th className="text-center text-lg text-black">用户名</th>
                <th className="text-center text-lg text-black">日期</th>
                <th className="text-center text-lg text-black">舰长</th>
                <th className="text-center text-lg text-black">提督</th>
                <th className="text-center text-lg text-black">总督</th>
                <th className="text-center text-lg text-black rounded-tr-lg">操作</th>
              </tr>
            </thead>
            <tbody>
            {submissions.map((submission, index) => (
              <tr key={index}>
                <td className="text-center">
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                    checked={selectedIds.includes(index)}
                  />
                </td>
                {Object.entries(submission).map(([key, value]) => {
                  const isEditing = editState.row === index && editState.column === key;
                  let inputType = "text"; // 默认为文本类型
                  if (key === "date") {
                    inputType = "date"; // 如果是日期列，使用日期选择器
                  }
                  
                  return (
                    <td key={key} className="text-center">
                      {isEditing ? (
                        <input
                          type={inputType} // 根据列类型决定输入框的类型
                          value={editState.value}
                          onChange={handleEditInfoChange}
                          onBlur={handleEditBlur}
                          autoFocus
                          size={editState.value.length || 1}
                          className="input input-bordered w-auto"
                        />
                      ) : (
                        <span onClick={() => handleEditClick(index, key, value)}>
                          {value}
                        </span>
                      )}
                    </td>
                  );
                })}

                  {/* Actions */}
                  <td className="text-center">
                    <div className="flex justify-center gap-2">
                      <button className="btn btn-md btn-accent text-white" onClick={() => handleEdit(index)}>
                        <FontAwesomeIcon icon={faEdit} />编辑
                      </button>
                      <button className="btn btn-md btn-error text-white" onClick={() => handleDelete(index)}>
                        <FontAwesomeIcon icon={faTrash} />删除
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

            </table>
          </div>
        )}
      </div>
      {showModal && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">{responseType === "msg" ? "消息" : "错误"}</h3>
            <p className="py-4">{modalContent}</p>
            <div className="modal-action">
              <button onClick={closeModal} className="btn">关闭</button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && editIndex !== -1 && (
        <div className="modal modal-open">
          <div className="modal-box">
          <h3 className="text-lg font-bold mb-4">编辑内容</h3>
                <div className="mb-2">
                  <label className="label">
                    <span className="label-text">舰长UID</span>
                  </label>
                  <input name="captainUID" type="text" value={currentEdit.captainUID} onChange={handleEditChange} className="input input-bordered w-full" />
                </div>
                <div className="mb-2">
                  <label className="label">
                    <span className="label-text">日期</span>
                  </label>
                  <input name="date" type="date" value={currentEdit.date} onChange={handleEditChange} className="input input-bordered w-full" />
                </div>
                <div className="mb-2">
                  <label className="label">
                    <span className="label-text">舰长</span>
                  </label>
                  <input name="jianzhang" type="number" value={currentEdit.jianzhang} onChange={handleEditChange} className="input input-bordered w-full" />
                </div>
                <div className="mb-2">
                  <label className="label">
                    <span className="label-text">提督</span>
                  </label>
                  <input name="tidu" type="number" value={currentEdit.tidu} onChange={handleEditChange} className="input input-bordered w-full" />
                </div>
                <div className="mb-2">
                  <label className="label">
                    <span className="label-text">总督</span>
                  </label>
                  <input name="zongdu" type="number" value={currentEdit.zongdu} onChange={handleEditChange} className="input input-bordered w-full" />
                </div>
                <div className="modal-action">
                  <button onClick={closeEdit} className="btn btn-error text-white">
                  <FontAwesomeIcon icon={faCircleXmark} style={{color: "#ffffff",}} />关闭
                    </button>
                  <button onClick={saveEdit} className="btn btn-success  text-white">
                    <FontAwesomeIcon icon={faSave} className="" style={{color: "#ffffff",}}/> 保存
                  </button>
                </div>
          </div>
        </div>
      )}
      {showDeleteConfirmModal && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">您确定要删除选中内容吗？</h3>
            <div className="modal-action">
              <button onClick={() => setShowDeleteConfirmModal(false)} className="btn btn-md">
              <FontAwesomeIcon icon={faBan} style={{color: "#000000",}} />取消(<span id="countdown">5</span>s)</button>
              <button onClick={confirmDelete} className="btn btn-error text-white btn-md">
              <FontAwesomeIcon icon={faCircleCheck} style={{color: "#ffffff",}} />确定</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SubmissionPage;
