import React, { useState } from 'react';

const Register = () => {
  const [step, setStep] = useState(1);
  const [uid, setUid] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  // 第一步提交处理
const handleStep1Submit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://v1.vup.name/api/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uid }),
    });

    const data = await response.json();
    if (response.ok) {
      if (data.msg) {
        setModalMessage(data.msg);
        setShowModal(true);

        // 如果收到的消息是'您已校验过，请进入下一步！'，则直接跳转到设置密码步骤
        if (data.code === 1) {
          setStep(3);
        }
      } else {
        setToken(data.token); 
        localStorage.setItem('register_token', data.token);
        setStep(2); 
      }
    } else {
      setModalMessage(data.error || '未知错误');
      setShowModal(true);
    }
  } catch (error) {
    console.error('请求处理错误:', error);
  }
};

  

  // 第二步提交处理
const handleStep2Submit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://v1.vup.name/api/regtoken', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uid, token }),
    });

    const data = await response.json();
    if (response.ok) {
      if (data.msg) {
        setModalMessage(data.msg);
        setShowModal(true);

        // 检查是否返回特定消息，如果是则直接跳转到第三步
        if (data.msg === "通过校验进行下一步") {
          setStep(3);
        }
      }
    } else {
      setModalMessage(data.error || '未知错误');
      setShowModal(true);
    }
  } catch (error) {
    console.error('请求处理错误:', error);
  }
};


  
  

  // 第三步提交处理
const handleStep3Submit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://v1.vup.name/api/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uid, password }),
    });

    const data = await response.json();
    if (response.ok) {
      if (data.msg) {
        // 检查是否返回“注册成功”消息，如果是则重定向到登录页面
        if (data.msg === "注册成功") {
          window.location.href = '/auth/login';
        } else {
          setModalMessage(data.msg);
          setShowModal(true);
        }
      } else {
        setStep(4);
      }
    } else {
      setModalMessage(data.error || '未知错误');
      setShowModal(true);
    }
  } catch (error) {
    console.error('请求处理错误:', error);
  }
};

  
  
  // 渲染不同步骤的表单
  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
           <><ul className="steps mb-8">
                <li data-content="1" className="step step-neutral">开始注册</li>
                <li data-content="2" className="step ">校验身份</li>
                <li data-content="4" className="step ">设置密码</li>
                <li data-content="√" className="step ">注册成功</li>
            </ul><form onSubmit={handleStep1Submit}>
                    <div className="label">
                        <span className="label-text">UID</span>
                    </div>
                    <input
                        type="text"
                        value={uid}
                        onChange={(e) => setUid(e.target.value)}
                        placeholder="请输入您的UID"
                        className="input input-bordered hover:input-primary w-full" />
                    <button type="submit" className="btn mt-4 w-full">提交</button>
                </form></>
        );
      case 2:
            return (
                <><ul className="steps mb-8">
                    <li data-content="1" className="step step-neutral">开始注册</li>
                    <li data-content="2" className="step step-neutral">校验身份</li>
                    <li data-content="4" className="step ">设置密码</li>
                    <li data-content="√" className="step ">注册成功</li>
                </ul><form onSubmit={handleStep2Submit}>

                        <div>
                            <p className="font-sans antialiased font-bold mb-4">您好，请将以下校验代码复制并发送给“<a href="https://message.bilibili.com/?spm_id_from=333.999.0.0#/whisper/mid3493271472966575" target="_blank" className="text-indigo-600 font-bold underline decoration-indigo-500 decoration-2 dark:text-slate-200">捞星星的堇夏</a>”，然后点击提交按钮！</p>
                            <div className="token-section">
                                <div className="token-container">
                                    <div className="token" id="token">
                                        {token}
                                    </div>
                                </div>
                                <button type="button" onClick={() => navigator.clipboard.writeText(token)} className="btn-copy">复制</button>
                            </div>
                            <button type="submit" className="btn w-full">提交</button>
                        </div></form></>
            );
      case 3:
        return (
            <><ul className="steps mb-8">
                <li data-content="1" className="step step-neutral">开始注册</li>
                <li data-content="2" className="step step-neutral">校验身份</li>
                <li data-content="4" className="step step-neutral">设置密码</li>
                <li data-content="√" className="step ">注册成功</li>
            </ul><form onSubmit={handleStep3Submit}>
                    <div className="label">
                        <span className="label-text">密码</span>
                    </div>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="请输入密码"
                        className="input input-bordered input-primary w-full" />
                    <button type="submit" className="btn mt-4 w-full">注册</button>
                </form></>
        );
      case 4:
        return (
            <><ul className="steps mb-8">
                <li data-content="1" className="step step-neutral">开始注册</li>
                <li data-content="2" className="step step-neutral">校验身份</li>
                <li data-content="4" className="step step-neutral">设置密码</li>
                <li data-content="√" className="step step-neutral">注册成功</li>
            </ul><div>
                    <p className="font-bold text-4xl flex justify-center my-8 text-green-500">恭喜您注册成功，请前往登录！</p>
                </div></>
        );
      default:
        return null;
    }
  };
  
  return (
    <div className="bg-cover bg-[url('/public/bg.jpg')]">
      <div className="centered-card">
        <div className="card w-11/12 sm:w-5/12 md:w-8/12 lg:w-5/12 xl:w-5/12">
          <p className="font-bold mb-6 text-2xl font-mono">八木百货主播后台</p>
          {renderFormStep()}
          {showModal && (
            <div className="modal modal-open">
              <div className="modal-box font-bold subpixel-antialiased">
                <h3 className="font-black text-xl mb-6">提醒</h3>
                <p>{modalMessage}</p>
                <div className="modal-action">
                  <button onClick={() => setShowModal(false)} className="btn">关闭</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
