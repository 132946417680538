import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavigationBar from '../pages/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileImage, faFileAlt, faFileVideo, faFileAudio, faDownload, faShare, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function LuboPage() {
  const [datesWithProjects, setDatesWithProjects] = useState({}); // 更新变量名以反映其存储的内容
  const [selectedDateFiles, setSelectedDateFiles] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [fetchedCoverDates, setFetchedCoverDates] = useState(new Set());
  const [coverLoadingStatus, setCoverLoadingStatus] = useState({});
  const [downloads, setDownloads] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [isDetailsVisible, setIsDetailsVisible] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareDescription, setShareDescription] = useState('');
  const [shareDownloads, setShareDownloads] = useState(1); // 默认值为1
  const [showLinkModal, setShowLinkModal] = useState(false); // 用于控制分享链接模态框的显示
  const [shareLink, setShareLink] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      const uid = localStorage.getItem('uid');
      const sessionToken = localStorage.getItem('session_token');
  
      try {
        const response = await axios.post('https://v1.vup.name/lubo/file', { uid, session_token: sessionToken });
        const content = response.data.content;
        const datesWithProjectsTemp = Object.keys(content).reduce((acc, date) => {
          const projects = content[date];
          acc[date] = {};
          Object.keys(projects).forEach(title => {
            const files = projects[title];
            // 假设封面文件总是以.jpg结尾，这里只是为了示例
            const coverFile = files.find(file => file.endsWith('.jpg')) || null;
            acc[date][title] = {
              files,
              cover: coverFile ? coverFile : null // 直接存储封面文件路径
            };
            // 如果存在封面，尝试重新获取封面图片的URL
            if (coverFile) {
              fetchCoverImage(date, title, coverFile);
            }
          });
          return acc;
        }, {});
        setDatesWithProjects(datesWithProjectsTemp);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
  
    fetchData();
  }, []); // 依赖数组为空，表示这个effect只在组件挂载时运行

  useEffect(() => {
    Object.entries(datesWithProjects).forEach(([date, projects]) => {
      Object.entries(projects).forEach(([title, project]) => {
        const coverKey = `${date}-${title}`;
        if (!fetchedCoverDates.has(coverKey) && project.cover) {
          fetchCoverImage(date, title, project.cover);
          setFetchedCoverDates(prev => new Set(prev).add(coverKey));
        }
      });
    });
  }, [datesWithProjects]); // 监听datesWithProjects的变化

  const handleDownload = async (date, path) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    const fileKey = `${date}-${path}`; // 用作唯一标识符
    let startTime = Date.now();
  
    try {
      const response = await axios.post('https://v1.vup.name/lubo/file/download', {
        uid,
        session_token: sessionToken,
        date,
        path
      }, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          const currentTime = Date.now();
          const duration = (currentTime - startTime) / 1000; // 秒
          const speed = (progressEvent.loaded / duration).toFixed(2); // bytes per second
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  
          setDownloads(currentDownloads => ({
            ...currentDownloads,
            [fileKey]: {
              progress: `${progress}%`,
              speed: `${speed} bytes/sec`
            }
          }));
        }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', path.split('/').pop());
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      // Optionally remove or update the state for the completed download
    } catch (error) {
      console.error('Error downloading file', error);
      // Optionally handle errors, e.g., by updating the state to indicate failure
    }
  };

  
  function formatSpeed(bytesPerSecond) {
    const kb = 1024;
    const mb = kb * 1024;
    if (bytesPerSecond < kb) return bytesPerSecond + ' B/s';
    else if (bytesPerSecond < mb) return (bytesPerSecond / kb).toFixed(2) + ' KB/s';
    else return (bytesPerSecond / mb).toFixed(2) + ' MB/s';
  }
  
  
  const fetchCoverImage = async (date, title, path) => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    
    // 更新封面加载状态为正在加载
    setCoverLoadingStatus(prev => ({...prev, [`${date}-${title}`]: true}));
  
    try {
      const response = await axios.post('https://v1.vup.name/lubo/file/download', { uid, session_token: sessionToken, date, path }, { responseType: 'blob' });
      const url = URL.createObjectURL(response.data);
      setDatesWithProjects(prev => ({
        ...prev,
        [date]: {
          ...prev[date],
          [title]: {
            ...prev[date][title],
            cover: url // 更新为实际的封面图片URL
          }
        }
      }));
      // 更新封面加载状态为加载完成
      setCoverLoadingStatus(prev => ({...prev, [`${date}-${title}`]: false}));
    } catch (error) {
      console.error('Error fetching cover image', error);
      // 标记封面加载失败
      setCoverLoadingStatus(prev => ({...prev, [`${date}-${title}`]: false}));
    }
  };
  
  const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch(extension) {
      case 'pdf':
        return faFilePdf;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return faFileImage;
      case 'txt':
        return faFileAlt;
      case 'mp4':
      case 'avi':
      case 'mov':
        return faFileVideo;
      case 'mp3':
      case 'wav':
      case 'aac':
        return faFileAudio;
      default:
        return faFileAlt; // 默认图标
    }
  };

  const handleCoverClick = (date, title) => {
    setSelectedDateFiles(datesWithProjects[date][title].files);
    setSelectedTitle(title); 
    setSelectedDate(date);
    setShowModal(true);
  };

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };
  
  const toggleDetailsVisibility = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const calculateTotalProgress = () => {
    const downloadEntries = Object.entries(downloads);
    if (downloadEntries.length === 0) return 0; // 如果没有下载项，进度为0
    const totalProgress = downloadEntries.reduce((acc, [, download]) => {
      const progress = parseFloat(download.progress) || 0; // 确保是有效数字，否则使用0
      return acc + progress;
    }, 0);
    const averageProgress = totalProgress / downloadEntries.length;
    return averageProgress.toFixed(0); // 返回平均进度，保留整数部分
  };

  const handleShare = (date, title) => {
    setSelectedDate(date); // 假设你已经有这样的状态
    setSelectedTitle(title); // 假设你已经有这样的状态
    setShowShareModal(true);
  };
  
  const handleModalSubmit = async () => {
    const uid = localStorage.getItem('uid');
    const sessionToken = localStorage.getItem('session_token');
    
    try {
      const response = await axios.post('https://v1.vup.name/lubo/share/join', {
        uid,
        session_token: sessionToken,
        date: selectedDate,
        path: selectedTitle, // 假设你已经存储了这个状态
        num: shareDownloads,
        share_describe: shareDescription
      });
      
      const { msg, token } = response.data;
      
      alert(`分享成功，您的文件分享链接为：https://vup.im/share/${token}`); // 你可以选择使用更友好的UI提示用户
      // 关闭模态框
      setShowShareModal(false);
      // 可选：清空表单
      setShareDescription('');
      setShareDownloads(1);
    } catch (error) {
      console.error('Error sharing file', error);
      alert('分享失败，请重试'); // 同样，考虑使用更友好的提示方式
    }
  };


  
  return (
    <div className="bg-slate-100 min-h-screen">
      <NavigationBar /> 
      <div className="container mx-auto px-4 mt-8">
      {Object.entries(datesWithProjects).map(([date, projects]) => (
        <div key={date}>
          <h2 className="text-2xl font-bold mb-2 mt-4 text-gray-700">{date}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.entries(projects).map(([title, project]) => (
              <div className="relative cursor-pointer shadow-lg rounded-lg bg-white overflow-hidden" onClick={() => handleCoverClick(date, title)}>
                {project.cover ? (
                  <>
                    <img src={project.cover} alt="封面" className="w-full h-32 sm:h-48 object-cover rounded-lg" />
                    <div className="absolute bottom-0 left-0 right-0 backdrop-blur-md bg-black/5 p-2">
                      <p className="text-center text-white font-bold">{title}</p>
                    </div>
                  </>
                ) : (
                  <div>
                  <div className="w-full h-32 sm:h-48 bg-gray-200 animate-pulse rounded-lg">
                  
                    </div>
                    <div className="absolute bottom-0 left-0 right-0 backdrop-blur-md bg-black/30 p-2">
                   
                    <p className="text-center text-white font-bold">{title}</p>
                  </div></div>
                )}
                <div className="absolute top-2 right-2">
                  <button onClick={(e) => { e.stopPropagation(); handleShare(date, title); }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                    <FontAwesomeIcon icon={faShare}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      {isPanelVisible && (
        <div className="fixed right-4 bottom-4 bg-white p-4 shadow-xl rounded-xl max-h-full  w-80 overflow-auto">
          <div className="flex justify-between items-center">
            <h4 className="font-bold text-lg">下载进度 <div className="ml-2 radial-progress w-5 h-5 text-[8px]  bg-gray-200 text-black-content border-4 border-gray-200" style={{"--value":`${calculateTotalProgress(downloads)}`,  "--thickness": "2px"}} role="progressbar">{calculateTotalProgress()}</div></h4>
            <button onClick={toggleDetailsVisibility}>
            {isDetailsVisible ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </button>
          </div>
          {isDetailsVisible && (
            // 下载详情展示
            Object.entries(downloads).map(([key, download]) => (
              <div key={key} className="mt-4">
                <div className="text-sm">{key.split('/').pop()}</div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-blue-600 h-2.5 rounded-full mt-1" style={{ width: download.progress }}></div>
                </div>
                <div className="text-xs text-gray-600">{download.progress} - {formatSpeed(parseFloat(download.speed))}
                </div>
              </div>
            ))
          )}
        </div>
      )}

      </div>

      {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full" onClick={() => setShowModal(false)}>
            <div className="relative top-20 mx-auto p-8 border w-4/5 sm:w-4/5 md:w-1/3 shadow-xl rounded-lg bg-white" onClick={e => e.stopPropagation()}>
              <h2 className="text-xl font-bold mb-6">文件列表 - {selectedTitle}</h2>
              <ul className="space-y-2">
              {
                selectedDateFiles.map((file, index) => {
                  const fileKey = `${selectedDate}-${file}`;
                  const fileDownloads = downloads[fileKey];
                  const isDownloading = fileDownloads && fileDownloads.progress !== '100%';
                  return (
                    <div key={index} className="relative bg-gray-100 rounded-md font-semibold shadow-sm overflow-hidden">
                      {isDownloading && (
                        <div className="absolute bg-blue-200" style={{ width: fileDownloads ? fileDownloads.progress : '0%', height: '100%' }}></div>
                      )}
                      <div className="relative p-3 flex justify-between items-center z-10">
                        <span className="flex items-center">
                          <FontAwesomeIcon icon={getFileIcon(file)} className="mr-2"  size="lg"/>
                          {file.split('/').pop()}
                        </span>
                        <div className="flex items-center">
                        {!isDownloading ? (
                           <>
                            <button onClick={() => handleShare(selectedDate, file)} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2">
                            <FontAwesomeIcon icon={faShare}/>
                            </button>
                            <button onClick={() => handleDownload(selectedDate, file)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                              <FontAwesomeIcon icon={faDownload}/>
                            </button>
                          </>
                        ) : (
                          <div className="text-sm">
                            {/* 使用formatSpeed来格式化显示下载速度 */}
                            <p>{fileDownloads ? formatSpeed(parseFloat(fileDownloads.speed)) : ''}</p>
                          </div>
                        )}
                        </div>
                      </div>
                    </div>
                  );
                })
              }

              </ul>
              <button className="mt-6 px-6 py-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-800" onClick={() => setShowModal(false)}>关闭</button>
            </div>
          </div>
        )
      }
      {showShareModal && (
        <div className="modal modal-open">
        <div className="modal-box">
          <h3 className="font-bold text-lg">分享文件</h3>
          <textarea className="textarea textarea-bordered w-full mt-4" placeholder="分享简介" value={shareDescription} onChange={(e) => setShareDescription(e.target.value)}></textarea>
          
          {/* 这里是滑动条部分 */}
          <div className="form-control mt-4">
            <label className="label" htmlFor="num">
              <span className="label-text font-bold">许可下载次数</span>
            </label>
            <input
              type="range"
              id="num"
              name="num"
              className="range range-primary range-xs"
              min="1"
              max="10"
              value={shareDownloads}
              onChange={(e) => setShareDownloads(Math.min(10, Math.max(1, parseInt(e.target.value, 10) || 1)))} 
            />
            <div className="w-full flex justify-between text-xs px-2 font-bold mt-1">
              <span>1</span><span>2</span><span>3</span><span>4</span>
              <span>5</span><span>6</span><span>7</span><span>8</span>
              <span>9</span><span>10</span>
            </div>
          </div>
          
          <div className="modal-action">
            <button className="btn" onClick={() => handleModalSubmit()}>分享</button>
            <button className="btn" onClick={() => setShowShareModal(false)}>关闭</button>
          </div>
        </div>
      </div>
      
      )}
    </div>
  );
}

export default LuboPage;
