import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import NavigationBar from '../pages/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPoo,faFire, faExclamationCircle, faUser, faReply, faStopwatch, faGear, faPenToSquare, faTriangleExclamation, faBan } from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';

const MyLiandongPage = () => {
    const [posts, setPosts] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [time, setTime] = useState('');
    const [content, setContent] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [confirmState, setConfirmState] = useState({});
    const [replyTo, setReplyTo] = useState('0');  // 用于保存被回复的 comment_id
    const [replyContent, setReplyContent] = useState('');
    const [selectedLdId, setSelectedLdId] = useState(null);  // 选中的 ld_id
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [replyToContent, setReplyToContent] = useState(''); // 被回复的内容
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportCommentId, setReportCommentId] = useState(null);
    const [reportReason, setReportReason] = useState('');
    const [reportToUid, setReportToUid] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState('');
    

    const navigate = useNavigate();


    useEffect(() => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        if (!session_token || !uid) {
            navigate('/auth/login');
        } else {
            fetchTypeList();
            fetchPosts();
        }
    }, [navigate]);

    

    const fetchTypeList = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');

        try {
            const response = await axios.post('https://v1.vup.name/liandongtype', {
                session_token,
                uid
            });
            setTypeList([{ type_id: '', type_name: '请选择类型' }, ...response.data]);
        } catch (error) {
            console.error("Error fetching type list:", error);
        }
    };
    const handleRemoveParticipant = async (participantId, ldId) => {
        const sessionToken = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            const response = await axios.post('https://v1.vup.name/rmldmember', {
                session_token: sessionToken,
                uid,
                ld_id: ldId,
                mb_uid: participantId
            });
            setModalMessage(response.data.message || '移出成功');
        } catch (error) {
            setModalMessage(error.response.data.error || '请求失败');
        }
        setShowModal(true);
    };
    const renderModal = () => {
        return showModal && (
            <div className="modal modal-open">
                <div className="modal-box font-medium text-xl">
                    <p>{modalMessage}</p>
                    <div className="modal-action">
                        <button className="btn btn-primary" onClick={() => setShowModal(false)}>关闭</button>
                    </div>
                </div>
            </div>
        );
    };
    
    const fetchPosts = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            const response = await axios.post('https://v1.vup.name/myld', {
                session_token,
                uid
            });
            const decodedPosts = response.data.map(post => ({
                ...post,
                content: decodeURIComponent(unescape(post.content))
            }));
            setPosts(decodedPosts);
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    };

    const currentUserUid = localStorage.getItem('uid');

    const renderParticipants = (participants, ldId, suid) => {
        if (!Array.isArray(participants)) {
            return null;
        }
        const currentUserUid = localStorage.getItem('uid');
        return (
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                {participants.map(participant => (
                    <div key={participant.uid} className="flex bg-gray-100 rounded-lg items-center justify-between p-2 shadow-md space-x-4">
                        <img className="w-10 h-10 rounded-full ml-2" src={`https://v1.vup.name/proxy/avatar?url=${participant.face}`} alt={participant.uname} />
                        <div className="flex flex-col flex-grow">
                            <span className="font-black text-sm">{participant.uname}</span>
                            <span className="font-medium text-sm">UID:{participant.uid}</span>
                        </div>
                        {(suid === currentUserUid || participant.uid === currentUserUid) && (
                            <button 
                                className="text-xs text-red-500 hover:text-red-700"
                                onClick={() => handleRemoveParticipant(participant.uid, ldId)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                            </button>
                        )}
                    </div>
                ))}
            </div>
        );
    };
    

    const handleReplyClick = (commentId, content, ldId) => {
        setReplyTo(commentId);  // 设置 replyTo 为被回复评论的 comment_id
        setReplyToContent(content); // 设置被回复的内容
        setReplyContent('');    // 清空回复输入
        setSelectedLdId(ldId);  // 设置当前选中的 ld_id
        setShowCommentModal(true); // 显示评论模态框
    };

    const handleCommentSubmit = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');
    
        try {
            const response = await axios.post('https://v1.vup.name/ldcomment', {
                session_token,
                uid,
                replay_to: replyTo || '0', // 使用 replyTo，如果未设置，则为 '0'
                ld_id: selectedLdId,
                content: replyContent
            });
            // 提交成功的处理
            setResponseMessage(response.data.msg || '评论已成功提交！');
            setShowResponseModal(true);
            resetReplyState(); // 重置回复相关的状态
            fetchPosts(); // 重新获取帖子列表
        } catch (error) {
            console.error("Error submitting comment:", error);
            // 提交失败的处理
            setResponseMessage("提交失败，请稍后再试。");
            setShowResponseModal(true);
        }
    };
    const renderCommentModal = () => (
        showCommentModal && (
            <div className="modal modal-open">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">回复评论</h3>
                    <p className="py-4 font-bold">被回复的内容:<span className="bg-gray-200 rounded-lg px-2 py-0.5">{replyToContent}</span></p>
                    <textarea 
                        className="textarea textarea-bordered w-full" 
                        placeholder="写下您的评论..." 
                        value={replyContent} 
                        onChange={(e) => setReplyContent(e.target.value)}
                    />
                    <div className="modal-action">
                        <button 
                            className="btn btn-neutral"
                            onClick={() => handleCommentSubmit()}
                        >
                            发表评论
                        </button>
                        <button 
                            className="btn"
                            onClick={() => setShowCommentModal(false)}
                        >
                            取消
                        </button>
                    </div>
                </div>
            </div>
        )
    );
    const resetReplyState = () => {
        setReplyTo('0');
        setReplyContent('');
        setShowCommentModal(false);
    };
    
    const renderComments = (comments, ldId, participants, suid) => {
        if (!Array.isArray(comments)) {
            return null;
        }
        const findOriginalComment = (replyToId) => {
            return comments.find(comment => comment.comment_id.toString() === replyToId);
        };
        // 辅助函数：计算时间差并格式化时间显示
        const calculateTimeDiff = (timeString) => {
            const now = new Date();
            const commentTime = new Date(timeString);
            const diffInSeconds = Math.floor((now - commentTime) / 1000);
    
            if (diffInSeconds < 10) {
                return "刚刚";
            } else if (diffInSeconds < 60) {
                return Math.floor(diffInSeconds) + "秒前";
            } else if (diffInSeconds < 3600) {
                return Math.floor(diffInSeconds / 60) + "分钟前";
            } else if (diffInSeconds < 86400 && now.getDate() === commentTime.getDate()) {
                return Math.floor(diffInSeconds / 3600) + "小时前";
            } else {
                return new Date(timeString).toLocaleString();
            }
        };
    
        // 用于找到特定 ID 的评论
        const findCommentById = (commentId) => {
            return comments.find(c => c.comment_id.toString() === commentId);
        };
    
        return (
            <div>
                {comments.map((comment) => {
                    const isReply = comment.replay_to !== '0';
                    const originalComment = isReply ? findOriginalComment(comment.replay_to) : null;    
    const userStatuses = getUserStatus(comment.uid, participants, suid);
    const statusLabels = userStatuses.map(status => {
        switch (status) {
            case 'superAdmin':
                return <span className="text-xs bg-amber-400 rounded-md shadow-lg p-0.5 pl-2 pr-2 mr-1 ml-1 text-white font-bold"><FontAwesomeIcon icon={faGear} className="mr-1 opacity-50 "/>管理员</span>;
            case 'creator':
                return <span className="text-xs bg-indigo-500 rounded-md shadow-lg p-0.5 pl-2 pr-2 mr-1 ml-1 text-white font-bold"><FontAwesomeIcon icon={faPenToSquare} className="mr-1 opacity-50 "/>创建者</span>;
            case 'exited':
                return <span className="text-xs bg-red-400 rounded-md shadow-lg p-0.5 pl-2 pr-2 mr-1 ml-1 text-white font-bold"><FontAwesomeIcon icon={faTriangleExclamation} className="mr-1 opacity-50 "/>该用户已退出活动</span>;
            default:
                return null;
        }
    });

    const isMainComment = comment.replay_to === '0';
    const commentClass = isMainComment ? 'chat chat-start' : 'chat chat-end';
    const repliedComment = isMainComment ? null : findCommentById(comment.replay_to);
    const formattedTime = calculateTimeDiff(comment.time);

    return (
        <div key={comment.comment_id} className={`chat ${comment.replay_to === '0' ? 'chat-start' : 'chat-end'}`}>
            <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                    <img alt="User avatar" src={`https://v1.vup.name/proxy/avatar?url=${comment.face}`} />
                </div>
            </div>
            <div className="chat-header font-bold flex flex-col sm:flex-row items-start sm:items-center">
                <span className="block sm:inline-block mr-2">{comment.uname}</span>
                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    {statusLabels.map((label, index) => <div key={index} className="mt-1 sm:mt-0">{label}</div>)}
                    <time className="chat-time text-xs opacity-50 bg-gray-100 rounded-lg p-0.5 pl-2 pr-2 mt-1 sm:mt-0 sm:ml-1">
                        <FontAwesomeIcon icon={faStopwatch} className="mr-1 opacity-50" />
                        {formattedTime}
                    </time>
                </div>
            </div>
            <div className="chat-bubble mt-1 break-word break-all">{comment.content}</div>
            {isReply && originalComment && (
                            <div className="chat-footer bg-gray-100 p-1 px-2 rounded-lg opacity-50 text-xs text-gray-500 mt-1 break-word break-all">
                                <FontAwesomeIcon icon={faReply} className="scale-x-[-1] scale-y-[-1] opacity-50"/> {originalComment.content}
                            </div>
                        )}
            <button
                className="btn btn-xs btn-outline mt-1"
                onClick={() => handleReplyClick(comment.comment_id, comment.content, ldId)}
            >
                回复
            </button>
        </div>
    );
})}
            </div>
        );
    };
    
    const handleSubmit = async () => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');

        try {
            await axios.post('https://v1.vup.name/liandong', {
                session_token,
                uid,
                type_id: selectedType,
                time,
                content
            });
            setShowModal(false);
        } catch (error) {
            console.error("Error submitting post:", error);
        }
    };

    const joinLiandong = async (ld_id) => {
        const session_token = localStorage.getItem('session_token');
        const uid = localStorage.getItem('uid');

        try {
            const response = await axios.post('https://v1.vup.name/joinliandong', {
                session_token,
                uid,
                ld_id
            });
            alert(response.data.msg); // 显示返回消息
        } catch (error) {
            console.error("Error joining liandong:", error);
            if (error.response && error.response.data) {
                alert(error.response.data.error);
            }
        }
    };

    const getStatusIcon = (state) => {
        switch (state) {
            case 0: return <FontAwesomeIcon icon={faUser} className="animate-bounce"/>;
            case 1: return <FontAwesomeIcon icon={faCheckCircle} className="animate-ping"/>;
            case 2: return <FontAwesomeIcon icon={faPoo} className="animate-bounce"/>;
            case 3: return <FontAwesomeIcon icon={faExclamationCircle} className="animate-bounce"/>;
            case 4: return <FontAwesomeIcon icon={faFire}  className="animate-bounce"/>;
            case 5: return <FontAwesomeIcon icon={faBan} className="animate-spin"/>;
            default: return null;
        }
    };
    const getUserStatus = (uid, participants, suid) => {
        let statuses = [];
    
        if (uid === "3537114169871275") {
            statuses.push('superAdmin'); // 超级管理员
        }
        if (uid === suid) {
            statuses.push('creator'); // 活动创建者
        }
        if (participants.some(participant => participant.uid === uid)) {
            statuses.push('participant'); // 普通参与者
        }
        if (statuses.length === 0) {
            statuses.push('exited'); // 已退出活动
        }
        return statuses;
    };

    const getStatusColor = (state) => {
        switch (state) {
            case 0: return 'bg-yellow-500 shadow-yellow-500/30';
            case 1: return 'bg-green-500 shadow-green-500/30';
            case 2: return 'bg-yellow-900 shadow-yellow-900/30';
            case 3: return 'bg-blue-500 shadow-blue-500/30';
            case 4: return 'bg-red-500 shadow-red-500/50';
            case 5: return 'bg-gray-500 shadow-gray-500/30';
            default: return 'bg-gray-300 shadow-gray-300/50';
        }
    };
    const renderReportModal = () => (
        showReportModal && (
            <div className="modal modal-open">
                <div className="modal-box">
                    <h3 className="font-bold text-lg mb-4">举报</h3>
                    <textarea 
                        className="textarea textarea-bordered w-full" 
                        placeholder="举报理由..." 
                        value={reportReason} 
                        onChange={(e) => setReportReason(e.target.value)}
                    />
                    <input
                        className="input input-bordered w-full my-2"
                        placeholder="举报的联动对象 UID"
                        value={reportToUid}
                        onChange={(e) => setReportToUid(e.target.value)}
                    />
                    <div className="modal-action">
                        <button 
                            className="btn btn-neutral"
                            onClick={() => handleSubmitReport()}
                        >
                            提交举报
                        </button>
                        <button 
                            className="btn"
                            onClick={() => setShowReportModal(false)}
                        >
                            取消
                        </button>
                    </div>
                </div>
            </div>
        )
    );
    const handleSubmitReport = async () => {
        try {
            const response = await axios.post('https://v1.vup.name/ldreport', {
                uid: localStorage.getItem('uid'),
                session_token: localStorage.getItem('session_token'),
                report_content: reportReason,
                report_to_uid: reportToUid,
                comment_id: reportCommentId,
                // ld_id: selectedLdId // 如果适用
            });
            // 显示提交成功的消息
            setAlertModalContent(response.data.message || '举报已提交');
            setShowAlertModal(true);
            // 重置举报模态框内容
            setReportReason('');
            setReportToUid('');
            // 关闭举报模态框
            setShowReportModal(false);
        } catch (error) {
            console.error("Error submitting report:", error);
            setAlertModalContent(error.response?.data?.message || '举报提交失败，请稍后再试。');
            setShowAlertModal(true);
        }
    };
    
    
    const handleReportClick = (commentId) => {
        // 设置 state 来跟踪举报的帖子或评论 ID
        setReportCommentId(commentId);
        // 显示举报模态框
        setShowReportModal(true);
    };
    

    const getStatusText = (state) => {
        const statusMap = {
            0: '自建联动',
            1: '公会活动',
            2: 'B站活动（答辩）',
            3: '跨公会联动',
            4: '热门活动',
            5: '终止'
        };
        return statusMap[state] || '未知状态';
    };
    
    const handleNewCommentClick = (ldId) => {
        setReplyTo('0');              // 设置 replyTo 为 '0'
        setReplyToContent('');        // 清空被回复的内容
        setReplyContent('');          // 清空回复输入
        setSelectedLdId(ldId);        // 设置选中的 ld_id
        setShowCommentModal(true);    // 显示评论模态框
    }

    return (
        <div className="bg-slate-100 min-h-screen">
            <NavigationBar />
            <div className="container mx-auto flex flex-col items-center mt-4 p-5 w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-2/5 relative w-full">
                <h2 className="font-bold text-3xl m-4">我参与的联动</h2>
                {renderReportModal()}
                {renderModal()}
                {posts.map(post => (
                    <div key={post.ld_id} className="mt-4 p-5 rounded-lg bg-white shadow-md w-7/8 sm:w-7/8 md:w-7/8 lg:w-7/8 xl:w-7/8 relative w-full">
                        
                        <div className="absolute top-2 left-2 flex items-center">
                            <div className={`rounded-full px-3 py-1 text-white ${getStatusColor(post.state)} shadow-lg`}>
                                {getStatusIcon(post.state)}
                                <span className="ml-2 font-bold">{getStatusText(post.state)}</span>
                            </div>
                            <button 
                                className="rounded-full ml-2 py-1 px-2 shadow-red-400/20 shadow-lg"
                                onClick={() => handleReportClick(post.comment_id)}
                                style={{ color: 'red' }}
                            >
                                <FontAwesomeIcon icon={faShieldHalved} beatFade />
                            </button>


                        </div>

                        <div className="absolute top-2 shadow-green-500/50 right-2 rounded-full px-3 py-1 text-white font-bold bg-green-500 ">
                            <span>{post.time}</span>
                        </div>
    
                        <div className="">
                        <div className="bg-slate-50 rounded-lg p-4 mt-8">
                            <div className="prose ">
                                <h2 className="text-violet-600 "># {post.ld_id} {post.type_name}</h2>
                                <Markdown>{post.content}</Markdown>
                            </div></div>
                            <h2 className="font-bold mt-4 mb-2">参与人</h2>
                            {renderParticipants(post.participants, post.ld_id, post.suid)}
                            <h2 className="font-bold">评论</h2>
                            {renderComments(post.comments, post.ld_id, post.participants, post.suid)}
                            {renderCommentModal()}
                            <button 
                                className="btn btn-neutral my-4 mx-auto block w-full"
                                onClick={() => handleNewCommentClick(post.ld_id)} 
                            >
                                添加评论
                            </button>
                        </div>
                    </div>
                ))}
                {showAlertModal && (
                    <div className="modal modal-open">
                        <div className="modal-box">
                            <p className="font-bold">{alertModalContent}</p>
                            <div className="modal-action">
                                <button className="btn" onClick={() => setShowAlertModal(false)}>关闭</button>
                            </div>
                        </div>
                    </div>
                )}
                {showResponseModal && (
                    <div className="modal modal-open">
                        <div className="modal-box">
                            <p>{responseMessage}</p>
                            <div className="modal-action">
                                <button className="btn btn-primary" onClick={() => setShowResponseModal(false)}>关闭</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MyLiandongPage;